import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Typography, Grid, Button, Card, CardContent, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import Page from "../../../components/Page";
import FormInput from "./FormInput";
import axios from "../../../axios";
import Snackbar from "../../../components/Snackbar";
import validate from "validate.js";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	inputContent: {
		marginTop: theme.spacing(1.5),
	},
	button: {
		marginTop: theme.spacing(3),
	},
}));

let schema = {};

const initialState = {
	isValid: false,
	values: {
		active: false,
	},
	touched: {
		active: true,
	},
	errors: {},
};

const Installation = ({ match }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const { imsi } = match.params;
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("");
	const [formState, setFormState] = useState(initialState);

	useEffect(() => {
		// if id isset then we want to fetch the user from the api and fill the text fields
		if (imsi) {
			axios
				.get(`/api/electrolysis/installation/${imsi}`)
				.then((response) => {
					console.log(response);

					const installation = response.data.installation;

					setFormState((prevState) => ({
						...prevState,
						values: {
							IMSI: installation.IMSI,
							installationNumber: installation.installationNumber.toString(),
							customerNumber: installation.customerNumber.toString(),
							location: installation.location,
							phoneNumber: installation.phoneNumber,
							email: installation.email,
							alarmHandlingCode: installation.alarmHandlingCode,
							powermoduleMaxAmp: installation.powermoduleMaxAmp,
							powermoduleMaxVolt: installation.powermoduleMaxVolt,
							minAmp: Math.round(((installation.minAmp * 5.0) / 1023) * 100) / 100,
							maxAmp: Math.round(((installation.maxAmp * 5.0) / 1023) * 100) / 100,
							minTime: installation.minTime / 1000,
							maxTime: installation.maxTime / 1000,
							minVoltFault: installation.minVoltFault,
							maxVoltFault: installation.maxVoltFault,
							primaryFlowCounter: installation.primaryFlowCounter,
							secondaryFlowCounter: installation.secondaryFlowCounter,
							minSecondaryFlow: installation.minSecondaryFlow,
							flushTime: installation.flushTime / 1000,
							flushTimeInterval: installation.flushTimeInterval / 1000 / 3600,
							useAutomaticFlushValve: installation.useAutomaticFlushValve,
							isFlushValve24V: installation.isFlushValve24V,
							primaryKValue: installation.primaryKValue,
							secondaryKValue: installation.secondaryKValue,
							isCombi: installation.isCombi,
							maxPrimaryPulsesInRegister: installation.maxPrimaryPulsesInRegister,
							maxSecondaryPulsesInRegister: installation.maxSecondaryPulsesInRegister,
							isVVB: installation.isVVB,
							useStandbyMode: installation.useStandbyMode,
							standbyActivationTime: installation.standbyActivationTime,
							galvanicVoltageLowerLimit: Math.round(((installation.galvanicVoltageLowerLimit * 330.0) / 1023) * 100) / 100,
							galvanicVoltageUpperLimit: Math.round(((installation.galvanicVoltageUpperLimit * 330.0) / 1023) * 100) / 100,
							minDistentHeatCooling: Math.floor((installation.minDistentHeatCooling * 150) / 1000),
							minHotWaterTemperature: Math.floor((installation.minHotWaterTemperature * 150) / 1000),
							minCirculationWaterTemperature: Math.floor((installation.minCirculationWaterTemperature * 150) / 1000),
							distentHeatForwardTemperatureCorrectionValue: installation.distentHeatForwardTemperatureCorrectionValue,
							distentHeatReturnTemperatureCorrectionValue: installation.distentHeatReturnTemperatureCorrectionValue,
							hotwaterTemperatureCorrectionValue: installation.hotwaterTemperatureCorrectionValue,
							circulationWaterTemperatureCorrectionValue: installation.circulationWaterTemperatureCorrectionValue,
							leakTime: installation.leakTime / 1000 / 60,
							isBWT: installation.isBWT,
							pinCode: installation.pinCode,
							APN: installation.APN,
							isAcidThreatment: installation.isAcidThreatment,
							isFlushValveLeaking: installation.isFlushValveLeaking,
							isUpdated: installation.isUpdated,
							faultCode: installation.faultCode,
							lastDataPackage: installation.lastDataPackage,
						},
						touched: {
							...prevState.touched,
							IMSI: true,
							installationNumber: true,
							customerNumber: true,
							location: true,
							phoneNumber: true,
							email: true,
							alarmHandlingCode: true,
							powermoduleMaxAmp: true,
							powermoduleMaxVolt: true,
							minAmp: true,
							maxAmp: true,
							minTime: true,
							maxTime: true,
							minVoltFault: true,
							maxVoltFault: true,
							primaryFlowCounter: true,
							secondaryFlowCounter: true,
							minSecondaryFlow: true,
							flushTime: true,
							flushTimeInterval: true,
							useAutomaticFlushValve: true,
							isFlushValve24V: true,
							primaryKValue: true,
							secondaryKValue: true,
							isCombi: true,
							maxPrimaryPulsesInRegister: true,
							maxSecondaryPulsesInRegister: true,
							isVVB: true,
							useStandbyMode: true,
							standbyActivationTime: true,
							galvanicVoltageLowerLimit: true,
							galvanicVoltageUpperLimit: true,
							minDistentHeatCooling: true,
							minHotWaterTemperature: true,
							minCirculationWaterTemperature: true,
							distentHeatForwardTemperatureCorrectionValue: true,
							distentHeatReturnTemperatureCorrectionValue: true,
							hotwaterTemperatureCorrectionValue: true,
							circulationWaterTemperatureCorrectionValue: true,
							leakTime: true,
							isBWT: true,
							pinCode: true,
							APN: true,
							isAcidThreatment: true,
							isFlushValveLeaking: true,
							isUpdated: true,
							faultCode: true,
							lastDataPackage: true,
						},
					}));
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [imsi]);

	const handleChange = (event) => {
		event.persist();

		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	const handleCheckboxChange = (event) => {
		event.persist();

		const {
			target: { value },
		} = event;

		const permissions = typeof value === "string" ? value.split(",") : value;

		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]: permissions,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	// Check if input has error
	const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState((prevFormState) => ({
			...prevFormState,
			isValid: !errors,
			errors: errors || {},
		}));
	}, [formState.values, setFormState]);

	const handleSnackbarClick = (message, severity) => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		setSnackbarOpen(true);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") return;
		setSnackbarMessage("");
		setSnackbarSeverity("");
		setSnackbarOpen(false);
	};

	const onUpdate = async () => {
		// let body = formState.values;
		setLoading(true);

		const data = {
			IMSI: formState.values.IMSI,
			customerNumber: formState.values.customerNumber,
			installationNumber: formState.values.installationNumber,
			location: formState.values.location,
			email: formState.values.email,
			isBWT: formState.values.isBWT,
			isVVB: formState.values.isVVB,
		};

		try {
			const updateInstallation = await axios.put("/api/electrolysis/installation/", data);

			if (updateInstallation.status === 200) {
				handleSnackbarClick("Installationen er blevet opdateret", "success");
			}
		} catch (error) {
			console.log(error, error.response);
			if (error.response) {
				if (error.response.status === 422) {
					const validationErrors = error.response.data.errors.map((error) => {
						var errors = "";
						for (var key in error) {
							errors += error[key] + "\n";
						}
						return errors;
					});
					handleSnackbarClick(validationErrors, "error");
				} else if (error.response.status === 409) {
					if (error.response.status) handleSnackbarClick("Kunne ikke opdatere installation", "error");
				} else if (error.response.status === 400 && error.response.data.errors) {
					if (error.response.data.errors[0].message) {
						const validationErrors = error.response.data.errors.map((error) => {
							return error.message;
						});
						handleSnackbarClick(validationErrors, "error");
					} else {
						handleSnackbarClick(error.response.data.errors, "error");
					}
				} else {
					console.log("There was an error creating/updating the user");
					handleSnackbarClick("Der gik noget galt", "error");
				}
			} else {
				console.log("There was an error creating/updating the user");
				handleSnackbarClick("Der gik noget galt", "error");
			}
		}
		setLoading(false);
	};

	return (
		<Page className={classes.root}>
			<Container maxWidth="lg">
				<Grid alignItems="flex-end" container justify="space-between" spacing={3}>
					<Grid item>
						<Typography component="h1" variant="h3">
							Opdater Elektrolysestyring
						</Typography>
					</Grid>
				</Grid>
				<FormInput
					hasError={hasError}
					formState={formState}
					handleChange={handleChange}
					handleCheckboxChange={handleCheckboxChange}
					className={classes.inputContent}
				/>

				<Card className={clsx(classes.root, classes.botton)}>
					<CardContent className={classes.cardContent}>
						<form>
							<Grid container spacing={10}>
								<Grid item xs>
									<div className={classes.formGroup} align="center">
										{loading ? (
											<CircularProgress />
										) : (
											<Button disabled={!formState.isValid} onClick={onUpdate} variant="contained" color="primary">
												Opdater installation
											</Button>
										)}
									</div>
								</Grid>
							</Grid>
						</form>
					</CardContent>
				</Card>
			</Container>
			{snackbarMessage && snackbarOpen && snackbarSeverity ? (
				<Snackbar open={snackbarOpen} autoHideDuration={6000} message={snackbarMessage} onClose={handleSnackbarClose} severity={snackbarSeverity} />
			) : null}
		</Page>
	);
};

Installation.propTypes = {
	match: PropTypes.object.isRequired,
};

export default Installation;

import { CircularProgress, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import Page from "../../../components/Page";
import Snackbar from "../../../components/Snackbar";
import BellAlertIcon from "../../../components/Icons/Bell-Alert";
import CheckCircle from "../../../components/Icons/Check-Circle";
import PauseCircle from "../../../components/Icons/Pause-Circle";
import SignalSlash from "../../../components/Icons/Signal-Slash";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	inputContent: {
		marginTop: theme.spacing(1.5),
	},
	button: {
		marginTop: theme.spacing(3),
	},
	doughnot: {
		maxWidth: "200px",
	},
	spacer: {
		margin: "30px 0",
	},
	list: {
		borderRight: "1px solid rgb(192,192,192)",
		borderBottom: "1px solid rgb(192,192,192)",
		borderRadius: "5px",
	},
	greenItem: {
		padding: "15px 0",
		borderRight: "1px solid rgb(192,192,192)",
		borderLeft: "1px solid rgb(192,192,192)",
		borderBottom: "1px solid rgb(192,192,192)",
		borderRadius: "5px",
		borderTop: "5px solid #38c728",
	},
	grayItem: {
		padding: "15px 0",
		borderRight: "1px solid rgb(192,192,192)",
		borderLeft: "1px solid rgb(192,192,192)",
		borderBottom: "1px solid rgb(192,192,192)",
		borderRadius: "5px",
		borderTop: "5px solid #707070",
	},
	blueItem: {
		padding: "15px 0",
		borderRight: "1px solid rgb(192,192,192)",
		borderLeft: "1px solid rgb(192,192,192)",
		borderBottom: "1px solid rgb(192,192,192)",
		borderRadius: "5px",
		borderTop: "5px solid #87cdde",
	},
	pinkItem: {
		padding: "15px 0",
		borderRight: "1px solid rgb(192,192,192)",
		borderLeft: "1px solid rgb(192,192,192)",
		borderBottom: "1px solid rgb(192,192,192)",
		borderRadius: "5px",
		borderTop: "5px solid #e9afc6",
	},
	yellowItem: {
		padding: "15px 0",
		borderRight: "1px solid rgb(192,192,192)",
		borderLeft: "1px solid rgb(192,192,192)",
		borderBottom: "1px solid rgb(192,192,192)",
		borderRadius: "5px",
		borderTop: "5px solid #ffe680",
	},
	redItem: {
		padding: "15px 0",
		borderRight: "1px solid rgb(192,192,192)",
		borderLeft: "1px solid rgb(192,192,192)",
		borderBottom: "1px solid rgb(192,192,192)",
		borderRadius: "5px",
		borderTop: "5px solid #ff5555",
	},
	kpiContainer: {
		marginTop: "5px",
	},
	Icons: {
		width: "20px",
		height: "20px",
	},
	IconsRed: {
		width: "20px",
		height: "20px",
		color: "red",
	},
	IconsGreen: {
		width: "20px",
		height: "20px",
		color: "green",
	},
}));

const Installation = () => {
	const classes = useStyles();
	const [loadingActiveInactive, setLoadingActiveInactive] = useState(false);
	const [loadingFaultyInstallationsCount, setLoadingFaultyInstallationsCount] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("");
	const [activeInstallations, setActiveInstallations] = useState(null);
	const [inactiveInstallations, setInactiveInstallations] = useState(null);

	const [faultyInstallationsCount, setFaultyInstallationsCount] = useState(null);
	const [lostConnectionCount, setLostConnectionCount] = useState(null);

	useEffect(() => {
		setLoadingActiveInactive(true);
		axios
			.get(`/api/electrolysis/installation/status`)
			.then((response) => {
				setActiveInstallations(response.data.active);
				setInactiveInstallations(response.data.deactive);
			})
			.catch((error) => {
				console.log(error);
			});
		setLoadingActiveInactive(false);
	}, [loadingActiveInactive]);

	useEffect(() => {
		setLoadingFaultyInstallationsCount(true);
		axios
			.get(`/api/electrolysis/installation/faultycount`)
			.then((response) => {
				setFaultyInstallationsCount(response.data.count);
			})
			.catch((error) => {
				console.log(error);
			});
		setLoadingFaultyInstallationsCount(false);
	}, []);

	useEffect(() => {
		setLoadingFaultyInstallationsCount(true);
		axios
			.get(`/api/electrolysis/installation/lostconnectioncount`)
			.then((response) => {
				setLostConnectionCount(response.data.lostConnectionCount);
			})
			.catch((error) => {
				console.log(error);
			});
		setLoadingFaultyInstallationsCount(false);
	}, []);

	const handleSnackbarClick = (message, severity) => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		setSnackbarOpen(true);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") return;
		setSnackbarMessage("");
		setSnackbarSeverity("");
		setSnackbarOpen(false);
	};

	return (
		<Page className={classes.root}>
			<Container maxWidth="lg">
				<Grid alignItems="flex-end" container justify="space-between" spacing={3}>
					<Grid item>
						<Typography component="h1" variant="h3">
							Elektrolyse oversigt
						</Typography>
					</Grid>
				</Grid>

				<Grid container spacing={2} className={classes.kpiContainer}>
					<Grid item xs={12} sm={6} md={3}>
						<div className={classes.blueItem}>
							<Typography align="center" component="h3" variant="h4" style={{ fontWeight: "bold" }}>
								Aktive <CheckCircle className={classes.IconsGreen} />
							</Typography>
							<Typography align="center" variant="h4">
								{loadingActiveInactive && !activeInstallations ? <CircularProgress /> : null}
								{activeInstallations ? activeInstallations : null}
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<div className={classes.grayItem}>
							<Typography align="center" component="h3" variant="h4" style={{ fontWeight: "bold" }}>
								Inaktive <PauseCircle className={classes.Icons} />
							</Typography>
							<Typography align="center" variant="h4">
								{loadingActiveInactive && !inactiveInstallations ? <CircularProgress /> : null}
								{inactiveInstallations ? inactiveInstallations : null}
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<div className={classes.pinkItem}>
							<Typography align="center" component="h3" variant="h4" style={{ fontWeight: "bold" }}>
								Fejlhæftede <BellAlertIcon className={classes.IconsRed} />
							</Typography>
							<Typography align="center" variant="h4">
								{loadingFaultyInstallationsCount && !faultyInstallationsCount ? <CircularProgress /> : null}
								{faultyInstallationsCount ? faultyInstallationsCount : null}
							</Typography>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<div className={classes.pinkItem}>
							<Typography align="center" component="h3" variant="h4" style={{ fontWeight: "bold" }}>
								Mistet forbindelse <SignalSlash className={classes.IconsRed} />
							</Typography>
							<Typography align="center" variant="h4">
								{lostConnectionCount}
							</Typography>
						</div>
					</Grid>
				</Grid>

				{loadingActiveInactive ? <CircularProgress /> : null}
			</Container>
			{snackbarMessage && snackbarOpen && snackbarSeverity ? (
				<Snackbar open={snackbarOpen} autoHideDuration={6000} message={snackbarMessage} onClose={handleSnackbarClose} severity={snackbarSeverity} />
			) : null}
		</Page>
	);
};

export default Installation;

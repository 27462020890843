import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";

import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Toolbar,
	Typography,
	Paper,
	Container,
	Button,
	TextField,
	Snackbar,
	CircularProgress,
} from "@material-ui/core";

import Page from "../../components/Page";
import axios from "../../axios";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{ id: "articleNumber", numeric: false, disablePadding: false, label: "Varenummer" },
	{ id: "count", numeric: false, disablePadding: false, label: "Antal" },
];

function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "default"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight:
		theme.palette.type === "light"
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
			  },
	title: {
		flex: "1 1 100%",
	},
}));

const EnhancedTableToolbar = (props) => {
	const classes = useToolbarStyles();
	const { numSelected } = props;

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			{numSelected > 0 ? (
				<Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
					{numSelected} selected
				</Typography>
			) : (
				<Typography className={classes.title} variant="h6" id="tableTitle" component="div"></Typography>
			)}
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	paper: {
		width: "100%",
		marginTop: "10px",
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	btnPaper: {
		padding: "15px 10px",
		margin: "15px 0",
	},
	noList: {
		marginTop: "50px",
	},
}));

export default function EnhancedTable({ match }) {
	const classes = useStyles();
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("id");
	const [selected, setSelected] = React.useState([]);
	const [articlesForCount, setArticlesForCount] = useState([]);
	const initials = useSelector((state) => state.auth.initials);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("");
	const { journalnumber } = match.params;
	const [isLoadingingArticles, setIsLoadingArticles] = useState(false);

	useEffect(() => {
		getCountedArticles();
	}, []);

	const getCountedArticles = () => {
		setIsLoadingArticles(true);
		axios
			.get(`/api/technician/articlecount/journalforedit/${journalnumber}`)
			.then((response) => {
				setArticlesForCount(response.data.articles);
				setIsLoadingArticles(false);
			})
			.catch((err) => {
				setIsLoadingArticles(false);
			});
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = articlesForCount.map((n) => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const handleChange = (articleNumber, event) => {
		event.persist();

		if (!isNaN(parseInt(event.target.value)) || event.target.value === "") {
			const value = event.target.value === "" ? "" : parseInt(event.target.value);

			const newArticleList = articlesForCount.map((article) => {
				if (article.articleNumber === articleNumber) {
					return { ...article, amount: value };
				}
				return article;
			});
			setArticlesForCount(newArticleList);
		}
	};

	const submitArticleCount = async () => {
		try {
			const response = await axios.put(`/api/technician/articlecount`, { articles: articlesForCount });

			getCountedArticles();

			handleSnackbarClick("Optællingsliste opdateret", "success");
		} catch (error) {
			console.log(error);
			handleSnackbarClick("Optællingsliste kunne ikke opdateres", "error");
		}
	};

	const handleSnackbarClick = (message, severity) => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		setSnackbarOpen(true);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") return;
		setSnackbarMessage("");
		setSnackbarSeverity("");
		setSnackbarOpen(false);
	};

	return (
		<Page className={classes.root}>
			<Container maxWidth="lg">
				<Grid alignItems="flex-end" container justify="space-between" spacing={3}>
					<Grid item>
						<Typography component="h1" variant="h3">
							Lager optælling
						</Typography>
						<Typography component="h2" gutterBottom variant="overline">
							Rediger optællin af varer {initials}
						</Typography>
					</Grid>
				</Grid>

				{articlesForCount.length > 0 ? (
					<>
						<Paper className={classes.paper}>
							<TableContainer>
								<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
									<EnhancedTableHead
										classes={classes}
										numSelected={selected.length}
										order={order}
										orderBy={orderBy}
										onSelectAllClick={handleSelectAllClick}
										onRequestSort={handleRequestSort}
										rowCount={articlesForCount.length}
									/>
									<TableBody>
										{stableSort(articlesForCount, getComparator(order, orderBy)).map((row, index) => {
											const isItemSelected = isSelected(row.id);

											return (
												<TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.id} selected={isItemSelected}>
													<TableCell>
														<Typography component="h6" variant="h3">
															{row.articleNumber}
														</Typography>

														{row.articleName}
													</TableCell>
													<TableCell align="right">
														<TextField
															onFocus={(event) => {
																event.target.select();
															}}
															inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
															fullWidth
															name={index}
															onChange={(event) => handleChange(row.articleNumber, event)}
															value={articlesForCount[index].amount}
															variant="outlined"
														/>
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
						<Paper className={classes.btnPaper}>
							<Grid alignItems="flex-end" container justify="space-between" spacing={3}>
								<Grid item></Grid>
								<Grid item>
									<Button
										align="right"
										color="primary"
										className={classes.button}
										variant="contained"
										onClick={() => {
											if (window.confirm("Vil du afsende optællingslisten?")) {
												submitArticleCount();
											}
										}}
									>
										Opdater optælling
									</Button>
								</Grid>
							</Grid>
						</Paper>
					</>
				) : (
					<Typography align="center">
						{isLoadingingArticles === true ? (
							<CircularProgress size={30} />
						) : (
							<Typography component="h6" variant="h4" align="center" className={classes.noList}>
								Du har ingen varer til optælling
							</Typography>
						)}
					</Typography>
				)}
				<Snackbar
					anchorOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
					severity={snackbarSeverity}
					open={snackbarOpen}
					autoHideDuration={18000}
					onClose={handleSnackbarClose}
					message={snackbarMessage}
				/>
			</Container>
		</Page>
	);
}

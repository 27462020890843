import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container } from "@material-ui/core";
import Page from "../../../components/Page";
import Header from "./Header.js";
import FormInput from "./FormInput";
import Snackbar from "../../../components/Snackbar";
import validate from "validate.js";
import axios from "../../../axios";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	inputContent: {
		marginTop: theme.spacing(1.5),
	},
	button: {
		marginTop: theme.spacing(1),
	},
}));

const updateIonBytterSchema = {
	id: {
		presence: { allowEmpty: false, message: "is required" },
		numericality: {
			onlyInteger: true,
			message: "Kan kun indeholde numre",
		},
		length: {
			is: 20,
			message: "skal være 20 numre langt",
		},
	},
	SalesNr: {},
	CustomerNr: {
		presence: { allowEmpty: false, message: "is required" },
		numericality: {
			onlyInteger: true,
			message: "Kan kun indeholde numre",
		},
		length: {
			minimum: 5,
			maximum: 8,
			message: "skal være 5 eller 8 numre langt",
		},
	},
	InstallNr: {
		presence: { allowEmpty: false, message: "is required" },
		numericality: {
			onlyInteger: true,
			message: "Kan kun indeholde numre",
		},
		length: {
			minimum: 1,
			maximum: 6,
			message: "skal være mellem 1 og 6 numre",
		},
	},
	CustomerName: {
		presence: { allowEmpty: false, message: "is required" },
	},
}

const alarmSchema = {
	...updateIonBytterSchema,
	Function: {
		presence: { allowEmpty: false, message: "is required" },
	},
};

const ionbytterSchema = {
	...updateIonBytterSchema,
	PostNr: {
		presence: { allowEmpty: false, message: "is required" },
		numericality: {
			onlyInteger: true,
			message: "Kan kun indeholde numre",
		},
		length: {
			is: 4,
			message: "skal være 4 numre langt",
		},
	},
	InstallationType: {
		presence: { allowEmpty: false, message: "is required" },
	},
	FilterType: {
		presence: { allowEmpty: false, message: "is required" },
	},
	FilterSize: {
		presence: { allowEmpty: false, message: "is required" },
	},
	Bypass: {
		presence: { allowEmpty: false, message: "is required" },
	},
	Hardness: {
		presence: { allowEmpty: false, message: "is required" },
	},
	WaterSensorType: {
		presence: { allowEmpty: false, message: "is required" },
	},
	WaterSensorPL: {
		presence: { allowEmpty: false, message: "is required" },
	},
	Function: {
		presence: { allowEmpty: false, message: "is required" },
	},
	PulseAtFullCapacity: {
		presence: { allowEmpty: false, message: "is required" },
	},
	KorrigeretKapacitet: {},
	Capacity: {},
	alarmPercent: {
		presence: { allowEmpty: false, message: "is required" },
		numericality: {
			onlyInteger: true,
			greaterThan: 0,
      		lessThanOrEqualTo: 99,
			message: "Kan kun indeholde numre fra 0 til 99",
		},
	}
};

const initialState = {
	isValid: false,
	values: {},
	touched: {
		active: true,
	},
	errors: {},
};

const InstallationCreate = ({match}) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("");
	const [formState, setFormState] = useState(initialState);
	const [isAlarm, setIsAlarm] = useState(false);
	const [schema, setSchema] = useState(ionbytterSchema);
	const { id } = match.params;
	const [isUpdate, setIsUpdate] = useState(false);

	useEffect(() => {
		if (id) {
			setIsUpdate(true);
			axios.get(`/api/installation/${id}`)
			.then((i) => {
				console.log(i);
				setFormState((prevState) => ({
					...prevState,
					values: {
						id: i.data.installation.id,
						SalesNr: i.data.installation.SalesNr,
						CustomerNr: i.data.installation.CustomerNr,
						InstallNr: i.data.installation.InstallNr,
						CustomerName: i.data.installation.CustomerName,
						PostNr: i.data.installation.PostNr,
						InstallationType: i.data.installation.InstallationType,
						FilterType: i.data.installation.FilterType,
						FilterSize: i.data.filterSize[0].id,
						Bypass: i.data.bypasses[0].MultiplyFactor,
						Hardness: i.data.installation.Hardness,
						WaterSensorType: i.data.installation.WaterSensorType,
						WaterSensorPL: i.data.installation.WaterSensorPL,
						Function: i.data.installation.configuration.func,
						PulseAtFullCapacity: i.data.installation.PulseAtFullCapacity,
						KorrigeretKapacitet: i.data.installation.KorrigeretKapacitet,
						Capacity: i.data.installation.Capacity,
						alarmPercent: i.data.installation.alarmPercent
					},
					touched: {
						id: true,
						SalesNr: true,
						CustomerNr: true,
						InstallNr: true,
						CustomerName: true,
						PostNr: true,
						InstallationType: true,
						FilterType: true,
						FilterSize: true,
						Bypass: true,
						Hardness: true,
						WaterSensorType: true,
						WaterSensorPL: true,
						Function: true,
						PulseAtFullCapacity: true,
						KorrigeretKapacitet: true,
						Capacity: true,
						alarmPercent: i.data.installation.alarmPercent
					},
				}));
			}).catch(err => console.log(err));
		}
	}, [id]);

	const handleChange = (event) => {
		event.persist();

		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	// Check if input has error
	const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

	useEffect(() => {
		const errors = validate(formState.values, schema);
		setFormState((prevFormState) => ({
			...prevFormState,
			isValid: !errors,
			errors: errors || {},
		}));
	}, [formState.values, setFormState, schema]);

	useEffect(() => {
		const f = parseInt(formState.values.Function);
		if (f === 0 || f === 2 || f === 3 || f === 4 || f === 5 || f === 6) {
			setIsAlarm(true);
			setSchema(alarmSchema);
		} else {
			setIsAlarm(false);
			setSchema(ionbytterSchema);
		}
	}, [formState.values.Function]);

	const handleSnackbarClick = (message, severity) => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		setSnackbarOpen(true);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") return;
		setSnackbarMessage("");
		setSnackbarSeverity("");
		setSnackbarOpen(false);
	};

	const onCreate = async () => {
		setLoading(true);

		try {
			var res;
			if (isUpdate) {
				res = await axios.put("/api/installation", formState.values);
			} else {
				res = await axios.post("/api/installation", formState.values);
			}

			handleSnackbarClick("Installation oprettet", "success");
		} catch (error) {
			handleSnackbarClick("Kunne ikke oprette installation", "error");
		}

		setLoading(false);
	};

	return (
		<Page className={classes.root}>
			<Container maxWidth="lg">
				<Header />
				<FormInput
					hasError={hasError}
					formState={formState}
					handleChange={handleChange}
					className={classes.inputContent}
					formDisabled={!formState.isValid}
					loading={loading}
					onClick={onCreate}
					setFormState={setFormState}
					isAlarm={isAlarm}
					isUpdate={isUpdate}
				/>
			</Container>
			{snackbarMessage && snackbarOpen && snackbarSeverity ? (
				<Snackbar open={snackbarOpen} autoHideDuration={6000} message={snackbarMessage} onClose={handleSnackbarClose} severity={snackbarSeverity} />
			) : null}
		</Page>
	);
};

InstallationCreate.propTypes = {
	match: PropTypes.object.isRequired
}

export default InstallationCreate;

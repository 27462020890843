import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Typography, Grid, Link } from "@material-ui/core";
import Page from "../Page";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	blueItem: {
		padding: "15px 0",
		borderRight: "1px solid rgb(192,192,192)",
		borderLeft: "1px solid rgb(192,192,192)",
		borderBottom: "1px solid rgb(192,192,192)",
		borderRadius: "5px",
		borderTop: "5px solid #01579b",
		"&:hover": {
			borderTop: "5px solid #01579b",
			borderRight: "1px solid #01579b",
			borderLeft: "1px solid #01579b",
			borderBottom: "1px solid #01579b",
			cursor: "pointer",
		},
	},
	kpiContainer: {
		marginTop: "5px",
	},
	link: {
		"&:hover": {
			textDecoration: "none",
		},
	},
	headerText: {
		marginTop: "15px ",
	},
}));

const Installation = () => {
	const classes = useStyles();
	const history = useHistory();
	const permissions = useSelector((state) => state.auth.permission);

	const redirectToSMS = () => {
		history.push("/sim/aktiver/sms");
	};

	const redirectToElektrolyse = () => {
		history.push("/sim/aktiver/elektrolyse");
	};

	const redirectMySim = () => {
		history.push("/sim/mine");
	};

	const redirectRegisterSim = () => {
		history.push("/sim/registrer");
	};

	const redirectElectrolysisInstallations = () => {
		history.push("/elektrolyse/installationer");
	};

	const redirectElectrolysisOverview = () => {
		history.push("/elektrolyse/overblik");
	};

	const redirectTechnicianArticleCount = () => {
		history.push("/tekniker/lageroptaelling");
	};

	return (
		<Page className={classes.root}>
			{permissions && (permissions.split(",").includes("SIMALL") || permissions.split(",").includes("ADMINISTRATOR")) ? (
				<Container maxWidth="lg">
					<Typography className={classes.header} align="center" variant="h3">
						Hurtig links
					</Typography>

					<Grid className={classes.headerText} alignItems="flex-end" container justify="space-between" spacing={3}>
						<Grid item>
							<Typography component="h1" variant="h3">
								Sim
							</Typography>
						</Grid>
					</Grid>

					<Grid container spacing={2} className={classes.kpiContainer}>
						<Grid item xs={12} sm={6} md={3}>
							<Link className={classes.link} onClick={redirectToSMS} color="inherit" underline="none">
								<div className={classes.blueItem}>
									<Typography align="center" component="h3" variant="h4" style={{ fontWeight: "bold" }}>
										Aktiver SMS
									</Typography>
								</div>
							</Link>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<Link className={classes.link} onClick={redirectToElektrolyse} color="inherit" underline="none">
								<div className={classes.blueItem}>
									<Typography align="center" component="h3" variant="h4" style={{ fontWeight: "bold" }}>
										Aktiver Data
									</Typography>
								</div>
							</Link>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<Link className={classes.link} onClick={redirectMySim} color="inherit" underline="none">
								<div className={classes.blueItem}>
									<Typography align="center" component="h3" variant="h4" style={{ fontWeight: "bold" }}>
										Mine sim
									</Typography>
								</div>
							</Link>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<Link className={classes.link} onClick={redirectRegisterSim} color="inherit" underline="none">
								<div className={classes.blueItem}>
									<Typography align="center" component="h3" variant="h4" style={{ fontWeight: "bold" }}>
										Registrer sim
									</Typography>
								</div>
							</Link>
						</Grid>
					</Grid>
				</Container>
			) : null}

			{permissions &&
			(permissions.split(",").includes("ELECTROLYSISVIEW") ||
				permissions.split(",").includes("ELECTROLYSISALL") ||
				permissions.split(",").includes("ADMINISTRATOR")) ? (
				<Container maxWidth="lg">
					<Grid className={classes.headerText} alignItems="flex-end" container justify="space-between" spacing={3}>
						<Grid item>
							<Typography component="h1" variant="h3">
								Elektrolyse
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={2} className={classes.kpiContainer}>
						<Grid item xs={12} sm={6} md={3}>
							<Link className={classes.link} onClick={redirectElectrolysisOverview} color="inherit" underline="none">
								<div className={classes.blueItem}>
									<Typography align="center" component="h3" variant="h4" style={{ fontWeight: "bold" }}>
										Overblik
									</Typography>
								</div>
							</Link>
						</Grid>

						<Grid item xs={12} sm={6} md={3}>
							<Link className={classes.link} onClick={redirectElectrolysisInstallations} color="inherit" underline="none">
								<div className={classes.blueItem}>
									<Typography align="center" component="h3" variant="h4" style={{ fontWeight: "bold" }}>
										Installationer
									</Typography>
								</div>
							</Link>
						</Grid>
					</Grid>
				</Container>
			) : null}

			{permissions &&
			(permissions.split(",").includes("TECHNICIAN") || permissions.split(",").includes("LEADER") || permissions.split(",").includes("ADMINISTRATOR")) ? (
				<Container maxWidth="lg">
					<Grid className={classes.headerText} alignItems="flex-end" container justify="space-between" spacing={3}>
						<Grid item>
							<Typography component="h1" variant="h3">
								Tekniker
							</Typography>
						</Grid>
					</Grid>

					<Grid container spacing={2} className={classes.kpiContainer}>
						<Grid item xs={12} sm={6} md={3}>
							<Link className={classes.link} onClick={redirectTechnicianArticleCount} color="inherit" underline="none">
								<div className={classes.blueItem}>
									<Typography align="center" component="h3" variant="h4" style={{ fontWeight: "bold" }}>
										Lageroptælling
									</Typography>
								</div>
							</Link>
						</Grid>
					</Grid>
				</Container>
			) : null}

			{/* {snackbarMessage && snackbarOpen && snackbarSeverity ? (
				<Snackbar open={snackbarOpen} autoHideDuration={6000} message={snackbarMessage} onClose={handleSnackbarClose} severity={snackbarSeverity} />
			) : null} */}
		</Page>
	);
};

export default Installation;

import {
	TextField,
	CircularProgress,
	Container,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
	Button,
	TableHead,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import Page from "../../../components/Page";
import Snackbar from "../../../components/Snackbar";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import BellAlertIcon from "../../../components/Icons/Bell-Alert";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { Line } from "react-chartjs-2";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	inputContent: {
		marginTop: theme.spacing(1.5),
	},
	button: {
		marginTop: theme.spacing(3),
	},
	doughnot: {
		maxWidth: "200px",
	},
	spacer: {
		margin: "15px 0",
	},
	list: {
		borderRight: "1px solid rgb(192,192,192)",
		borderBottom: "1px solid rgb(192,192,192)",
		borderRadius: "5px",
	},
	item: {
		padding: "5px 0",
		marginTop: theme.spacing(3),
		borderRight: "1px solid rgb(192,192,192)",
		borderLeft: "1px solid rgb(192,192,192)",
		borderBottom: "1px solid rgb(192,192,192)",
		borderRadius: "5px",
	},
	updateBtn: {
		marginRight: "10px",
	},
	BellAlertIcon: {
		width: "25px",
		height: "25px",
		color: "red",
	},
	fromDate: {
		marginRight: "10px",
	},
}));

const Installation = ({ match }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const { imsi } = match.params;
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("");
	const [installation, setInstallation] = useState(null);
	const history = useHistory();
	const permissions = useSelector((state) => state.auth.permission);
	const [faultCodes, setFaultCodes] = useState([]);
	const [chartData, setChartData] = useState({
		labels: [],
		type: "Line",
		datasets: [
			{
				label: ["flow sidste 7 dage i timen"],
				data: [],
				fill: "false",
				backgroundColor: ["#36A2EB"],
				borderColor: ["#36A2EB"],
				borderWidth: 1,
			},
		],
	});

	const [tempChartData, setTempChartData] = useState({
		labels: [],
		type: "Line",
		datasets: [
			{
				label: ["Brugsvand varmt"],
				data: [],
				fill: "false",
				backgroundColor: ["#ff5555"],
				borderColor: ["#ff5555"],
				borderWidth: 1,
			},
			{
				label: ["Brugsvand cirkulation"],
				data: [],
				fill: "false",
				backgroundColor: ["#87cdde"],
				borderColor: ["#87cdde"],
				borderWidth: 1,
			},
			{
				label: ["Fjernvarme fremløb"],
				data: [],
				fill: "false",
				backgroundColor: ["#5fd38d"],
				borderColor: ["#5fd38d"],
				borderWidth: 1,
			},
			{
				label: ["fjernvarme return"],
				data: [],
				fill: "false",
				backgroundColor: ["#ffe680"],
				borderColor: ["#ffe680"],
				borderWidth: 1,
			},
			{
				label: ["fjernvarme afkøl"],
				data: [],
				fill: "false",
				backgroundColor: ["#e9afc6"],
				borderColor: ["#e9afc6"],
				borderWidth: 1,
			},
		],
	});

	const updateFaultCodes = (faultCode) => {
		if (faultCode > 0) {
			let errors = [];

			let dec = faultCode;

			if (dec >= 512) {
				errors.push({ faultCode: 512, message: "Galvanisk spænding for høj eller for lav" });
				dec = dec - 512;
			}

			if (dec >= 256) {
				errors.push({ faultCode: 256, message: "Høj volt" });
				dec = dec - 256;
			}

			if (dec >= 128) {
				errors.push({ faultCode: 128, message: "Lav volt" });
				dec = dec - 128;
			}

			if (dec >= 64) {
				errors.push({ faultCode: 64, message: "Intet forbrug i 3 døgn" });
				dec = dec - 64;
			}

			if (dec >= 32) {
				errors.push({ faultCode: 32, message: "Kontinuerligt vand forbrug i et døgn" });
				dec = dec - 32;
			}

			if (dec >= 16) {
				errors.push({ faultCode: 16, message: "Flowfejl på sekundær vandmåler" });
				dec = dec - 16;
			}

			if (dec >= 8) {
				errors.push({ faultCode: 8, message: "Udslamningsventil ikke lukket korrekt" });
				dec = dec - 8;
			}

			if (dec >= 4) {
				errors.push({ faultCode: 4, message: "Fjernvarme afkøling er for lille" });
				dec = dec - 4;
			}

			if (dec >= 2) {
				errors.push({ faultCode: 2, message: "Cirkulationsvandtemperatur er for lav" });
				dec = dec - 2;
			}

			if (dec >= 1) {
				errors.push({ faultCode: 1, message: "Brugsvandstemperatur er for lav" });
				dec = dec - 1;
			}

			setFaultCodes(errors);
		}
	};

	useEffect(() => {
		// if the id is set we can fetch the installation from the api
		if (imsi) {
			setLoading(true);
			axios
				.get(`/api/electrolysis/installation/${imsi}`)
				.then((response) => {
					console.log(response);

					setInstallation(response.data.installation);
					updateFaultCodes(response.data.installation.faultCode);
				})
				.catch((error) => {
					console.log(error);
				});
			setLoading(false);
		}
	}, [imsi, loading]);

	useEffect(() => {
		if (installation && installation.isVVB === 1) {
			axios
				.get(`/api/electrolysis/statistic/flow/${installation.customerNumber}/${installation.installationNumber}`)
				.then((response) => {
					const labels = response.data.statistics.map((flow) => {
						return moment(flow.date).format("DDMMYYYY h:mm");
					});
					const data = response.data.statistics.map((flow) => {
						return flow.flow;
					});

					const updatedChartData = {
						...chartData,
					};

					const updatedTempChartData = {
						...tempChartData,
					};

					updatedChartData.labels = labels;
					updatedChartData.datasets[0].data = data;

					updatedTempChartData.labels = labels;

					setChartData(updatedChartData);
					setTempChartData(updatedTempChartData);
				})
				.catch((error) => {
					console.log(error);
				});

			axios
				.get(`/api/electrolysis/statistic/temps/${installation.customerNumber}/${installation.installationNumber}`)
				.then((response) => {
					console.log(response.data);

					const labels = response.data.statistics.map((flow) => {
						return moment(flow.date).format("DDMMYYYY h:mm");
					});

					let hotwater = [];
					let circulationswater = [];
					let distHeatingForward = [];
					let distHeatingReturn = [];
					let distHeatingCool = [];

					for (var i = 0; i < response.data.statistics.length; i++) {
						hotwater.push(response.data.statistics[i].hotwater);
						circulationswater.push(response.data.statistics[i].circulationswater);
						distHeatingForward.push(response.data.statistics[i].distHeatingForward);
						distHeatingReturn.push(response.data.statistics[i].distHeatingReturn);
						distHeatingCool.push(response.data.statistics[i].distHeatingForward - response.data.statistics[i].distHeatingReturn);
					}

					const updatedTempChartData = {
						...tempChartData,
					};

					updatedTempChartData.labels = labels;
					updatedTempChartData.datasets[0].data = hotwater;
					updatedTempChartData.datasets[1].data = circulationswater;
					updatedTempChartData.datasets[2].data = distHeatingForward;
					updatedTempChartData.datasets[3].data = distHeatingReturn;
					updatedTempChartData.datasets[4].data = distHeatingCool;

					console.log(updatedTempChartData);

					setTempChartData(updatedTempChartData);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [installation]);

	const handleSnackbarClick = (message, severity) => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		setSnackbarOpen(true);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") return;
		setSnackbarMessage("");
		setSnackbarSeverity("");
		setSnackbarOpen(false);
	};

	const deleteInstallation = async () => {
		const url =
			"/api/electrolysis/installation/" +
			installation.customerNumber +
			"/" +
			installation.installationNumber +
			"/" +
			installation.IMSI +
			"/" +
			installation.isBWT +
			"/" +
			installation.isVVB;

		try {
			await axios.delete(url);

			history.push("/elektrolyse/installationer");
		} catch (error) {
			console.log(error);
			handleSnackbarClick("Kunne ikke slette installation!", "error");
		}
	};

	return (
		<Page className={classes.root}>
			<Container maxWidth="lg">
				<Grid alignItems="flex-end" container justify="space-between" spacing={3}>
					<Grid item>
						<Typography component="h1" variant="h3">
							Installations detaljer
						</Typography>
						<Typography component="h2" gutterBottom variant="overline">
							Elektrolysestyring
						</Typography>
					</Grid>
					{permissions &&
					(permissions.split(",").includes("ADMINISTRATOR") ||
						permissions.split(",").includes("ELECTROLYSISDEL") ||
						permissions.split(",").includes("ELECTROLYSISUPD")) ? (
						<>
							<Grid item>
								{permissions.split(",").includes("ADMINISTRATOR") || permissions.split(",").includes("ELECTROLYSISUPD") ? (
									<Button
										className={classes.updateBtn}
										color="primary"
										variant="contained"
										component={RouterLink}
										to={"/elektrolyse/installationer/" + imsi + "/opdater"}
									>
										Opdater Installation
									</Button>
								) : null}
								{permissions.split(",").includes("ADMINISTRATOR") || permissions.split(",").includes("ELECTROLYSISDEL") ? (
									<Button
										color="secondary"
										variant="contained"
										onClick={() => {
											if (window.confirm("Er du sikker på at du vil slette den installation?")) {
												deleteInstallation();
											}
										}}
									>
										Slet installation
									</Button>
								) : null}
							</Grid>
						</>
					) : null}
				</Grid>
				{loading ? <CircularProgress /> : null}

				{installation ? (
					<Grid container spacing={1} className={classes.spacer}>
						<Grid item xs={12} sm={6}>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="simple table">
									<TableBody>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>ICC</b>
											</TableCell>
											<TableCell align="right">{installation.IMSI}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Kundenummer</b>
											</TableCell>
											<TableCell align="right">{installation.customerNumber}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Installationsnummer</b>
											</TableCell>
											<TableCell align="right">{installation.installationNumber}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Lokation</b>
											</TableCell>
											<TableCell align="right">{installation.location}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Email</b>
											</TableCell>
											<TableCell align="right">{installation.email}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="simple table">
									<TableBody>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Aktiv fejlkode</b>
											</TableCell>
											<TableCell align="right">{installation.faultCode == 0 ? "Ingen fejl" : installation.faultCode}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Sidste data pakke</b>
											</TableCell>
											<TableCell align="right">
												{installation.lastDataPackage ? moment(installation.lastDataPackage).format("DD-MM-YYYY HH:mm:ss") : "Aldrig sendt data"}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Dancat</b>
											</TableCell>
											<TableCell align="right">{installation.isCombi === 1 ? "Ja" : "Nej"}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Standby?</b>
											</TableCell>
											<TableCell align="right">{installation.useStandbyMode === 1 ? "Ja" : "Nej"}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>VVB eller Unit?</b>
											</TableCell>
											<TableCell align="right">{installation.isVVB === 1 ? "VVB" : "Unit"}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
				) : null}

				{installation ? (
					<Grid container spacing={1} className={classes.spacer}>
						<Grid item xs={12} sm={6}>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell style={{ fontWeight: "bold", color: "#005d8f" }}>Behandlingsparametre</TableCell>
											<TableCell align="center">
												<b>Min</b>
											</TableCell>
											<TableCell align="right">
												<b>Max</b>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Amp</b>
											</TableCell>
											<TableCell align="center">
												{Math.round(
													(((installation.powermoduleMaxAmp > 0 ? installation.powermoduleMaxAmp : 5) * installation.minAmp) / 1023 +
														Number.EPSILON) *
														10
												) / 10}
											</TableCell>
											<TableCell align="right">
												{Math.round(
													(((installation.powermoduleMaxAmp > 0 ? installation.powermoduleMaxAmp : 5) * installation.maxAmp) / 1023 +
														Number.EPSILON) *
														10
												) / 10}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Tid</b>
											</TableCell>
											<TableCell align="center">{installation.minTime * 0.001}s</TableCell>
											<TableCell align="right">{installation.maxTime * 0.001}s</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell style={{ fontWeight: "bold", color: "#005d8f" }}>Behandlingsfejlgrænser</TableCell>
											<TableCell align="center">
												<b>Min</b>
											</TableCell>
											<TableCell align="right">
												<b>Max</b>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Volt spænding</b>
											</TableCell>
											<TableCell align="center">
												{Math.round(
													(((installation.powerModuleMaxVolt > 0 ? installation.powerModuleMaxVolt : 24) * installation.minVoltFault) / 1023 +
														Number.EPSILON) *
														10
												) / 10}
											</TableCell>
											<TableCell align="center">
												{Math.round(
													(((installation.powerModuleMaxVolt > 0 ? installation.powerModuleMaxVolt : 24) * installation.maxVoltFault) / 1023 +
														Number.EPSILON) *
														10
												) / 10}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Galvaniskspænding</b>
											</TableCell>
											<TableCell align="center">{Math.round(((installation.galvanicVoltageLowerLimit * 3.3) / 1023) * 100)} [mV.]</TableCell>
											<TableCell align="center">{Math.round(((installation.galvanicVoltageUpperLimit * 3.3) / 1023) * 100)} [mV.]</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
				) : null}

				{installation ? (
					<>
						{faultCodes.length > 0 || installation.lostConnection === 1 ? (
							<>
								<Typography align="center" component="h3" variant="h4" style={{ fontWeight: "bold", color: "#005d8f" }}>
									Aktive fejl på styring <BellAlertIcon className={classes.BellAlertIcon} />
								</Typography>
								<TableContainer component={Paper}>
									<Table className={classes.table} size="small" aria-label="simple table">
										<TableBody>
											{faultCodes.map((faultCode) => {
												return (
													<TableRow>
														<TableCell component="th" scope="row">
															<BellAlertIcon className={classes.BellAlertIcon} /> {faultCode.faultCode + " - " + faultCode.message}
														</TableCell>
													</TableRow>
												);
											})}
											{installation.lostConnection === 1 ? (
												<TableRow>
													<TableCell component="th" scope="row">
														<BellAlertIcon className={classes.BellAlertIcon} /> Styring har mistet forbindelse til GSM nettet
													</TableCell>
												</TableRow>
											) : null}
										</TableBody>
									</Table>
								</TableContainer>
							</>
						) : null}
					</>
				) : null}

				{installation ? (
					<>
						{installation.isVVB === 1 ? (
							<>
								<Grid container justify="space-between" spacing={3} className={classes.root}>
									<Grid item>
										<Typography component="h1" variant="h3">
											Statistik
										</Typography>
									</Grid>
									<Grid item>
										<TextField
											id="date"
											label="Fra"
											type="date"
											defaultValue="2024-02-06"
											className={classes.fromDate}
											InputLabelProps={{
												shrink: true,
											}}
										/>
										<TextField
											id="date"
											label="Til"
											type="date"
											defaultValue="2024-02-13"
											className={classes.textField}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</Grid>
								</Grid>
								<Grid container>
									<Typography gutterBottom component="h2">
										Temperaturer sidste 7 dage
									</Typography>
									<Line height={100} data={tempChartData} />
									<Typography gutterBottom component="h2" className={classes.root}>
										Flow Liter/timen
									</Typography>
									<Line height={100} data={chartData} />
								</Grid>
							</>
						) : null}
					</>
				) : null}
			</Container>
			{snackbarMessage && snackbarOpen && snackbarSeverity ? (
				<Snackbar open={snackbarOpen} autoHideDuration={6000} message={snackbarMessage} onClose={handleSnackbarClose} severity={snackbarSeverity} />
			) : null}
		</Page>
	);
};

Installation.propTypes = {
	match: PropTypes.object.isRequired,
};

export default Installation;

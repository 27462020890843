import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Snackbar,
	Container,
	Card,
	CardContent,
	Typography,
	Grid,
	TextField,
	Button,
	CircularProgress,
	FormControl,
	Select,
	InputLabel,
	MenuItem,
	Checkbox,
} from "@material-ui/core";
import validate from "validate.js";
import axios from "../../../../axios";
import Modal from "../../../../components/UI/Modal/Modal";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: "30px",
		paddingBottom: "30px",
	},
	content: {
		paddingTop: "30px",
	},
	formValue: {
		marginTop: "10px",
	},
	formButton: {
		marginTop: "30px",
	},
	header: {
		marginTop: "25px ",
		fontWeight: "bold",
	},
	simId: {
		paddingLeft: "15px",
	},
	chooseSimContainer: {
		marginTop: "10px",
	},
}));

const initialState = {
	isValid: false,
	values: {
		isVVB: false,
	},
	touched: { isVVB: true },
	errors: {},
};

const schema = {
	kundenummer: {
		presence: { allowEmpty: false, message: "is required" },
		length: {
			minimum: 5,
			maximum: 8,
			message: "skal være mellem 5 og 8 numre",
		},
		numericality: {
			onlyInteger: true,
			message: "kan kun indeholde numre",
		},
	},
	installationsnummer: {
		presence: { allowEmpty: false, message: "is required" },
		length: {
			minimum: 1,
			message: "skal minimum være et nummer langt",
		},
		numericality: {
			onlyInteger: true,
			message: "kan kun indeholde numre",
		},
	},
	simId: {
		presence: { allowEmpty: false, message: "is required" },
		numericality: {
			onlyInteger: true,
			message: "kan kun indeholde numre",
		},
	},
	isVVB: {
		presence: { allowEmpty: false },
	},
};

const SMS = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("");
	const [formState, setFormState] = useState(initialState);
	const [simModal, setSimModal] = useState(false);
	const [sims, setSims] = useState([]);

	useEffect(() => {
		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
			},
			touched: {
				...prevFormState.touched,
			},
		}));

		axios.get(`/api/sim?type=DATA`).then((response) => {
			setSims(response.data.technicianSims);
		});
	}, []);

	// Change handler for input changed
	const handleChange = (event) => {
		event.persist();

		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	// Check if input has error
	const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState((prevFormState) => ({
			...prevFormState,
			isValid: !errors,
			errors: errors || {},
		}));
	}, [formState.values]);

	const handleSnackbarClick = (message, severity) => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		setSnackbarOpen(true);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") return;
		setSnackbarMessage("");
		setSnackbarSeverity("");
		setSnackbarOpen(false);
	};

	const onActivateSIM = async () => {
		setLoading(true);

		try {
			const data = formState.values;

			const response = await axios.post("/api/sim/activate/electrolysis", data);

			if (response.status === 200 || response.status === 201) {
				handleSnackbarClick(response.data.message, "success");
				setFormState(initialState);
			}
		} catch (error) {
			console.log(error.response);
			if (error.response) {
				handleSnackbarClick(error.response.data.message, "error");
			} else {
				handleSnackbarClick("Der skete en fejl.", "error");
			}
		}

		setLoading(false);
	};

	const handleModalClose = () => {
		setSimModal(false);
	};

	const handleModalOpen = () => {
		setSimModal(true);
	};

	return (
		<Container className={classes.content} maxWidth="lg">
			<Card>
				<Typography className={classes.header} align="center" variant="h3">
					Aktivering af Data kort på elektrolysestyring
				</Typography>
				<CardContent className={classes.content}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<TextField
								size="small"
								fullWidth
								label="Kundenummer"
								name="kundenummer"
								error={hasError("kundenummer")}
								helperText={hasError("kundenummer") ? formState.errors.kundenummer[0] : null}
								value={formState.values.kundenummer || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								size="small"
								fullWidth
								label="Installationsnummer"
								name="installationsnummer"
								error={hasError("installationsnummer")}
								helperText={hasError("installationsnummer") ? formState.errors.installationsnummer[0] : null}
								value={formState.values.installationsnummer || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3} alignItems="center" className={classes.chooseSimContainer}>
						<Grid item>
							<InfoIcon onClick={handleModalOpen} />
						</Grid>
						<Grid item xs>
							<FormControl size="small" fullWidth variant="outlined">
								<InputLabel id="simId">Vælg sim kort</InputLabel>
								<Select labelId="simId" value={formState.values.simId || ""} onChange={handleChange} name="simId" label="Vælg sim kort">
									<MenuItem value="">
										<em>None</em>
									</MenuItem>

									{sims.map((sim) => {
										// console.log(first)
										return <MenuItem value={sim.id}>{sim.icc}</MenuItem>;
									})}
								</Select>
							</FormControl>
						</Grid>
						<Modal
							open={simModal}
							handleClose={handleModalClose}
							title={"Sim kort nummer"}
							description={"Sim kort nummeret kan findes på sim kortet i får udleveret under icc eller på selve nano sim kortet."}
							image={"css.png"}
						/>
					</Grid>
					<Grid container spacing={3} alignItems="center" className={classes.chooseSimContainer}>
						<Checkbox name="isVVB" onChange={handleChange} value={formState.values.isVVB || false} />
						VVB?
					</Grid>
					<Grid className={classes.formButton}>
						{loading ? (
							<CircularProgress />
						) : (
							<Button disabled={!formState.isValid} onClick={onActivateSIM} variant="contained" color="primary">
								Opret installation
							</Button>
						)}
					</Grid>
					<Snackbar
						anchorOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
						severity={snackbarSeverity}
						open={snackbarOpen}
						autoHideDuration={18000}
						onClose={handleSnackbarClose}
						message={snackbarMessage}
					/>
				</CardContent>
			</Card>
		</Container>
	);
};

export default SMS;

// import React from "react";
import SimCard from "@material-ui/icons/SimCard";
import Category from "@material-ui/icons/Category";
import AccessibilityNew from "@material-ui/icons/AccessibilityNew";
import Opacity from "@material-ui/icons/Opacity";
import BarChartIcon from "@material-ui/icons/BarChart";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";

export default [
	{
		items: [
			// {
			// 	icon: Category,
			// 	permission: ["ADMINISTRATOR", "TECHNICIAN"],
			// 	title: "Lageroptælling",
			// 	href: "/tekniker/lageroptælling",
			// },
			{
				title: "Sim",
				href: "/sim",
				permission: ["ADMINISTRATOR", "SIMALL"],
				icon: SimCard,
				items: [
					{
						permission: ["ADMINISTRATOR", "SIMALL"],
						title: "Aktiver SMS boks",
						href: "/sim/aktiver/sms",
					},
					{
						permission: ["ADMINISTRATOR", "SIMALL"],
						title: "Aktiver Elektrolyse",
						href: "/sim/aktiver/elektrolyse",
					},
					{
						permission: ["ADMINISTRATOR", "SIMALL"],
						title: "Aktiver Forhandler",
						href: "/sim/aktiver/forhandler",
					},
					{
						permission: ["ADMINISTRATOR", "SIMALL"],
						title: "Mine sim kort",
						href: "/sim/mine",
					},
					{
						permission: ["ADMINISTRATOR", "SIMALL"],
						title: "Registrer nyt sim kort",
						href: "/sim/registrer",
					},
					{
						permission: ["ADMINISTRATOR", "SIMALL"],
						title: "SMS modtaget",
						href: "/sim/modtaget",
					},
				],
			},
			{
				title: "Tekniker",
				href: "/tekniker",
				permission: ["ADMINISTRATOR", "TECHNICIAN", "LEADER"],
				icon: AccessibilityNew,
				items: [
					{
						permission: ["ADMINISTRATOR", "TECHNICIAN"],
						title: "Lageroptælling",
						href: "/tekniker/lageroptaelling",
					},
					{
						permission: ["ADMINISTRATOR", "LEADER"],
						title: "Hjælp Lageroptælling",
						href: "/tekniker/hjaelplageroptaelling",
					},
					{
						permission: ["ADMINISTRATOR"],
						title: "Arkiv all",
						href: "/tekniker/lageroptaelling/arkivall",
					},
				],
			},
			{
				title: "Elektrolyse",
				href: "/elektrolyse",
				permission: ["ADMINISTRATOR", "ELECTROLYSISVIEW", "ELECTROLYSISALL"],
				icon: Opacity,
				items: [
					{
						permission: ["ADMINISTRATOR", "ELECTROLYSISALL"],
						title: "Overblik",
						href: "/elektrolyse/overblik",
					},
					{
						permission: ["ADMINISTRATOR", "ELECTROLYSISVIEW", "ELECTROLYSISALL"],
						title: "Installationer",
						href: "/elektrolyse/installationer",
					},
				],
			},
			{
				title: "IOT",
				href: "/installationer",
				permission: ["ADMINISTRATOR", "IOTVIEW", "IOTALL"],
				icon: BarChartIcon,
				items: [
					{
						permission: ["ADMINISTRATOR", "IOTALL"],
						title: "Alle installationer",
						href: "/installationer/alle",
					},
					{
						permission: ["ADMINISTRATOR", "IOTVIEW", "IOTALL"],
						title: "Mine installationer",
						href: "/installationer/mine",
					},
					{
						permission: ["ADMINISTRATOR", "IOTVIEW", "IOTALL"],
						title: "Opret installation",
						href: "/installationer/opret",
					},
				],
			},
			{
				title: "Diverse",
				href: "/diverse",
				permission: ["ADMINISTRATOR", "TECHNICIAN"],
				icon: ImportContactsIcon,
				items: [
					{
						permission: ["ADMINISTRATOR", "TECHNICIAN"],
						title: "Hårdheder",
						href: "/diverse/hårdheder",
					},
				],
			},
			{
				title: "Administration",
				href: "/administration",
				permission: ["ADMINISTRATOR"],
				icon: SettingsIcon,
				items: [
					{
						permission: ["ADMINISTRATOR"],
						title: "Brugere",
						href: "/administration/brugere",
					},
				],
			},
		],
	},
];

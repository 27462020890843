import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import BellAlertIcon from "../../../components/Icons/Bell-Alert";
import SignalSlash from "../../../components/Icons/Signal-Slash";
import moment from "moment";
import {
	Button,
	Container,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Typography,
} from "@material-ui/core";
import axios from "../../../axios";
import Page from "../../../components/Page";
import SearchBar from "../../../components/SearchBar";
import { useLocation, useHistory } from "react-router-dom";

// import Header from "./Header";
import Edit from "@material-ui/icons/Edit";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{ id: "id", numeric: false, disablePadding: false, label: "ID" },
	{ id: "msisdn", numeric: false, disablePadding: false, label: "SMS Nummer" },
	{ id: "message", numeric: false, disablePadding: false, label: "Besked" },
	{ id: "sent", numeric: true, disablePadding: false, label: "Sendt" },
];

function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "default"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	BellAlertIcon: {
		width: "15px",
		height: "15px",
		color: "red",
	},
}));

export default function EnhancedTable() {
	const classes = useStyles();
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(50);
	const [messages, setMessages] = useState([]);
	const [searchString, setSearchString] = useState("");
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		let { search } = location;
		let _searchString = null;

		if (search) {
			search = search.substr(1);

			let searchJSON = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

			if ("searchstring" in searchJSON) {
				_searchString = searchJSON["searchstring"];
			}

			setSearchString(_searchString);

			axios.get(`/api/gatewaymessages/search/${_searchString}`).then((response) => {
				setMessages(response.data.messages);
			});
		} else {
			axios.get(`/api/gatewaymessages/last50`).then((response) => {
				setMessages(response.data.messages);
			});
		}
	}, []);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = messages.map((n) => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSearch = async (e) => {
		e.preventDefault();
		try {
			let searchResults = "";

			if (searchString != "") {
				searchResults = await axios.get(`/api/gatewaymessages/search/${searchString}`);
			} else {
				searchResults = await axios.get(`/api/gatewaymessages/last50`);
			}

			history.push("?searchstring=" + searchString);

			if (!searchResults) {
				console.log("Searching failed");
			}

			setMessages(searchResults.data.messages);
		} catch (error) {
			setMessages([]);
			console.log(error);
			console.log(error.response);
		}
	};

	return (
		<Page className={classes.root}>
			{/* <Container maxWidth={false}> */}
			<Container>
				<Grid alignItems="flex-end" container justify="space-between" spacing={3}>
					<Grid item>
						<Typography component="h1" variant="h3">
							SMS beskeder modtaget
						</Typography>
						<Typography component="h2" gutterBottom variant="overline">
							SMS Boks
						</Typography>
					</Grid>
				</Grid>
				<form onSubmit={handleSearch}>
					<SearchBar searchString={searchString} setSearchString={setSearchString} onSearch={handleSearch} />
				</form>
				<Paper className={classes.paper}>
					{/* <EnhancedTableToolbar numSelected={selected.length} /> */}
					<TableContainer>
						<Table className={classes.table} size="small" aria-label="enhanced table">
							<EnhancedTableHead
								classes={classes}
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={messages.length}
							/>
							<TableBody>
								{stableSort(messages, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										const labelId = `enhanced-table-checkbox-${index}`;

										let message = null;
										if (row.payload) {
											let buff = new Buffer(row.payload, "base64");
											message = buff.toString("ascii");
										} else {
											message = row.message;
										}

										return (
											<TableRow hover onClick={(event) => handleClick(event, row.id)} tabIndex={-1} key={row.id}>
												<TableCell id={labelId} scope="row">
													{row.id}
												</TableCell>
												<TableCell>{row.msisdn}</TableCell>
												<TableCell>{message}</TableCell>
												<TableCell align="right">{moment.unix(row.senttime).format("YYYY-MM-DD HH:mm:SS")}</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						labelRowsPerPage="Rækker per side"
						rowsPerPageOptions={[25, 50, 100, 250]}
						component="div"
						count={messages.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
			</Container>
		</Page>
	);
}

/*

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Card, CardContent, Typography, Paper, Table, TableContainer, TableBody, TableHead, TableCell, TableRow, CircularProgress } from "@material-ui/core";
import SearchBar from "../../../components/SearchBar";
import axios from "../../../axios";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: "30px",
		paddingBottom: "30px",
	},
	content: {
		paddingTop: "30px",
	},
	formValue: {
		marginTop: "10px",
	},
	table: {
		minWidth: 650,
	},
	tableContainer: {
		padding: "0 20px 40px 20px",
	},
	headerText: {
		padding: "20px 20px 0 20px",
	},
}));

const Registrations = () => {
	const classes = useStyles();
	const [searchString, setSearchString] = useState("");
	const [messages, setMessages] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);

		axios
			.get("/api/gatewaymessages/last50")
			.then((response) => {
				console.log(response.data);
				setMessages(response.data.messages);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
			});
	}, []);

	const onSearch = async (e) => {
		let str = searchString;

		if (str) {
			setLoading(true);

			if (str.charAt(0) === "+") {
				str = str.substring(1);
			}

			try {
				const response = await axios.get("/api/gatewaymessages/search/" + str);
				console.log(response.data);
				setMessages(response.data.messages);
			} catch (error) {
				console.log(error);
			}
			setLoading(false);
		} else {
			setLoading(true);

			try {
				const response = await axios.get("/api/gatewaymessages/last50");
				console.log(response.data);
				setMessages(response.data.messages);
			} catch (error) {
				console.log(error);
			}
			setLoading(false);
		}
	};

	return (
		<Container className={classes.content} maxWidth="lg">
			<Card>
				<Typography className={classes.headerText} variant="h2" component="h3">
					SMS'er modtaget {loading ? <CircularProgress size={15} /> : null}
				</Typography>
				<CardContent className={classes.content}>
					<SearchBar searchString={searchString} setSearchString={setSearchString} onSearch={onSearch} />
				</CardContent>
				<CardContent>
					<TableContainer component={Paper}>
						<Table className={classes.table} size="small" aria-label="a dense table">
							<TableHead>
								<TableRow>
									<TableCell>Id</TableCell>
									<TableCell>SMS Nummer</TableCell>
									<TableCell>Besked</TableCell>
									<TableCell align="right">Sendt</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{messages.map((row) => {
									let message = null;
									if (row.payload) {
										let buff = new Buffer(row.payload, "base64");
										message = buff.toString("ascii");
									} else {
										message = row.message;
									}

									return (
										<TableRow key={row.id}>
											<TableCell component="th" scope="row">
												{row.id}
											</TableCell>
											<TableCell>{row.msisdn}</TableCell>
											<TableCell>{message}</TableCell>
											<TableCell align="right">{moment.unix(row.senttime).format("YYYY-MM-DD HH:mm:SS")}</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</CardContent>
			</Card>
		</Container>
	);
};

export default Registrations;
*/

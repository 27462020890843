import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Paper, Button, Input } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
	},
	search: {
		flexGrow: 1,
		height: 42,
		padding: theme.spacing(0, 2),
		display: "flex",
		alignItems: "center",
	},
	searchIcon: {
		marginRight: theme.spacing(2),
		color: theme.palette.icon,
	},
	searchInput: {
		flexGrow: 1,
	},
	searchButton: {
		marginLeft: theme.spacing(2),
	},
}));

function Search({ searchString, setSearchString, onSearch, className, ...rest }) {
	const classes = useStyles();

	return (
		<div {...rest} className={clsx(classes.root, className)}>
			<Paper className={classes.search} elevation={1}>
				<SearchIcon className={classes.searchIcon} />
				<Input
					autoFocus
					onChange={(e) => setSearchString(e.target.value)}
					value={searchString}
					className={classes.searchInput}
					disableUnderline
					placeholder="Søg"
				/>
			</Paper>
			<Button className={classes.searchButton} onClick={onSearch} size="large" variant="contained" color="primary">
				Søg
			</Button>
		</div>
	);
}

Search.propTypes = {
	className: PropTypes.string,
	onSearch: PropTypes.func,
};

export default Search;

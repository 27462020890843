import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardHeader, CardContent, TextField, Grid, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Checkbox, ListItemText } from "@material-ui/core";
import axios from "../../../axios";

const useStyles = makeStyles((theme) => ({
	root: {},
	formGroup: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	cardContent: {
		paddingTop: theme.spacing(1),
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function Form({ isUpdate, hasError, formState, handleChange, handleCheckboxChange, className, ...rest }) {
	const classes = useStyles();
	const [permissions, setPermissions] = useState([]);

	useEffect(() => {
		axios
			.get("/api/permission")
			.then((response) => {
				console.log(response.data);
				setPermissions(response.data.permissions);
			})
			.catch((error) => console.log(error));
	}, []);

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<CardHeader title="" variant="outlined" />
			<CardContent className={classes.cardContent}>
				<form>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								size="small"
								autoFocus
								fullWidth
								label="Navn"
								name="name"
								error={hasError("name")}
								helperText={hasError("name") ? formState.errors.name[0] : null}
								value={formState.values.name || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								size="small"
								fullWidth
								label="Initialer"
								name="initials"
								error={hasError("initials")}
								helperText={hasError("initials") ? formState.errors.initials[0] : null}
								value={formState.values.initials || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid>
						<div className={classes.formGroup}>
							<TextField
								size="small"
								fullWidth
								label="Email"
								name="email"
								error={hasError("email")}
								helperText={hasError("email") ? formState.errors.email[0] : null}
								value={formState.values.email || ""}
								onChange={handleChange}
								variant="outlined"
								type="email"
							/>
						</div>
					</Grid>
					<Grid>
						<div className={classes.formGroup}>
							<FormControl size="small" variant="outlined" fullWidth>
								<InputLabel id="permissions">Rettigheder</InputLabel>
								<Select
									labelId="permissions"
									multiple
									value={formState.values.permissions}
									onChange={handleCheckboxChange}
									renderValue={(selected) => selected.join(",")}
									name="permissions"
									label="Rettigheder"
								>
									{formState.values.permissions &&
										permissions.map((permission) => (
											<MenuItem key={permission.permission} value={permission.permission}>
												<Checkbox checked={formState.values.permissions.indexOf(permission.permission) > -1} />
												<ListItemText primary={permission.permission} />
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</div>
					</Grid>
					<Grid container spacing={3} alignItems="center">
						<Checkbox color="primary" name="active" onChange={handleChange} checked={formState.values.active || false} />
						Aktiv?
					</Grid>
					{!isUpdate ? (
						<Grid container spacing={3}>
							<Grid item xs>
								<TextField
									size="small"
									type="password"
									fullWidth
									label="Password"
									name="password"
									error={hasError("password")}
									helperText={hasError("password") ? formState.errors.password[0] : null}
									value={formState.values.password || ""}
									onChange={handleChange}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs>
								<TextField
									size="small"
									type="password"
									fullWidth
									label="Confirm password"
									name="confirmpassword"
									error={hasError("confirmpassword")}
									helperText={hasError("confirmpassword") ? formState.errors.confirmpassword[0] : null}
									value={formState.values.confirmpassword || ""}
									onChange={handleChange}
									variant="outlined"
								/>
							</Grid>
						</Grid>
					) : null}
				</form>
			</CardContent>
		</Card>
	);
}

Form.propTypes = {
	className: PropTypes.string,
	hasError: PropTypes.func,
	formState: PropTypes.object,
	handleChange: PropTypes.func,
	handleCheckboxChange: PropTypes.func,
	isUpdate: PropTypes.bool,
};

export default Form;

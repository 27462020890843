import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, TextField, Grid, Typography, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {},
	formGroup: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	cardContent: {
		paddingTop: theme.spacing(1),
	},
	sectionHeader: {
		padding: "16px 24px 12px 24px",
	},
	sectionHeaderAf: {
		padding: "0px 24px 12px 24px",
	},
}));

function Form({ hasError, formState, handleChange, handleCheckboxChange, className, ...rest }) {
	const classes = useStyles();

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<Typography className={classes.sectionHeader} variant="h4" component="h4">
				Generelt
			</Typography>
			<CardContent className={classes.cardContent}>
				<form>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								size="small"
								fullWidth
								label="Kundenummer"
								name="customerNumber"
								error={hasError("customerNumber")}
								helperText={hasError("customerNumber") ? formState.errors.customerNumber[0] : null}
								value={formState.values.customerNumber || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs>
							<TextField
								size="small"
								fullWidth
								label="Installationsnummer"
								name="installationNumber"
								error={hasError("installationNumber")}
								helperText={hasError("installationNumber") ? formState.errors.installationNumber[0] : null}
								value={formState.values.installationNumber || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								size="small"
								fullWidth
								label="Email"
								name="email"
								error={hasError("email")}
								helperText={hasError("email") ? formState.errors.email[0] : null}
								value={formState.values.email || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs>
							<TextField
								size="small"
								fullWidth
								label="Lokation"
								name="location"
								error={hasError("location")}
								helperText={hasError("location") ? formState.errors.location[0] : null}
								value={formState.values.location || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								disabled
								size="small"
								fullWidth
								label="IMSI"
								name="IMSI"
								error={hasError("IMSI")}
								helperText={hasError("IMSI") ? formState.errors.IMSI[0] : null}
								value={formState.values.IMSI || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs>
							<FormControl size="small" fullWidth variant="outlined">
								<InputLabel id="status">Status</InputLabel>
								<Select labelId="status" value={formState.values.isBWT || 0} onChange={handleChange} name="isBWT" label="Status">
									<MenuItem value="1">Aktiv</MenuItem>
									<MenuItem value="0">Inaktiv</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs>
							<FormControl size="small" fullWidth variant="outlined">
								<InputLabel id="vvborunit">VVB eller Unit?</InputLabel>
								<Select labelId="vvborunit" value={formState.values.isVVB || 0} onChange={handleChange} name="isVVB" label="VVB eller Unit?">
									<MenuItem value={1}>VVB</MenuItem>
									<MenuItem value={0}>Unit</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</form>
			</CardContent>
			<Typography className={classes.sectionHeaderAf} variant="h4" component="h4">
				Behandling
			</Typography>
			<CardContent className={classes.cardContent}>
				<form>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								disabled
								size="small"
								fullWidth
								label="Min ampere [A]"
								name="minAmp"
								error={hasError("minAmp")}
								helperText={hasError("minAmp") ? formState.errors.minAmp[0] : null}
								value={formState.values.minAmp || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs>
							<TextField
								disabled
								size="small"
								fullWidth
								label="Maks ampere [A]"
								name="maxAmp"
								error={hasError("maxAmp")}
								helperText={hasError("maxAmp") ? formState.errors.maxAmp[0] : null}
								value={formState.values.maxAmp || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								disabled
								size="small"
								fullWidth
								label="Min. behandlinstid [sek.]"
								name="minTime"
								error={hasError("minTime")}
								helperText={hasError("minTime") ? formState.errors.minTime[0] : null}
								value={formState.values.minTime || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs>
							<TextField
								disabled
								size="small"
								fullWidth
								label="Maks behandlingstid [sek.]"
								name="maxTime"
								error={hasError("maxTime")}
								helperText={hasError("maxTime") ? formState.errors.maxTime[0] : null}
								value={formState.values.maxTime || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								disabled
								size="small"
								fullWidth
								label="Min. galvaniskspæding [mV.]"
								name="galvanicVoltageLowerLimit"
								error={hasError("galvanicVoltageLowerLimit")}
								helperText={hasError("galvanicVoltageLowerLimit") ? formState.errors.galvanicVoltageLowerLimit[0] : null}
								value={formState.values.galvanicVoltageLowerLimit || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs>
							<TextField
								disabled
								size="small"
								fullWidth
								label="Maks galvaniskspænding [mV.]"
								name="galvanicVoltageUpperLimit"
								error={hasError("galvanicVoltageUpperLimit")}
								helperText={hasError("galvanicVoltageUpperLimit") ? formState.errors.galvanicVoltageUpperLimit[0] : null}
								value={formState.values.galvanicVoltageUpperLimit || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
				</form>
			</CardContent>
			<Typography className={classes.sectionHeaderAf} variant="h4" component="h4">
				Vandmålere
			</Typography>
			<CardContent className={classes.cardContent}>
				<form>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								disabled
								size="small"
								fullWidth
								label="Min. flow for bi-måler"
								name="minSecondaryFlow"
								error={hasError("minSecondaryFlow")}
								helperText={hasError("minSecondaryFlow") ? formState.errors.minSecondaryFlow[0] : null}
								value={formState.values.minSecondaryFlow || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								disabled
								size="small"
								fullWidth
								label="Maks. antal pulser i register [stk.]"
								name="maxPrimaryPulsesInRegister"
								error={hasError("maxPrimaryPulsesInRegister")}
								helperText={hasError("maxPrimaryPulsesInRegister") ? formState.errors.maxPrimaryPulsesInRegister[0] : null}
								value={formState.values.maxPrimaryPulsesInRegister || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								disabled
								size="small"
								fullWidth
								label="Tid uden pulser i løbet af 1 døgn [minutter]"
								name="leakTime"
								error={hasError("leakTime")}
								helperText={hasError("leakTime") ? formState.errors.leakTime[0] : null}
								value={formState.values.leakTime || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
				</form>
			</CardContent>
			<Typography className={classes.sectionHeaderAf} variant="h4" component="h4">
				Udslamning
			</Typography>
			<CardContent className={classes.cardContent}>
				<form>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								disabled
								size="small"
								fullWidth
								label="Udslamningstid [sek.]"
								name="flushTime"
								error={hasError("flushTime")}
								helperText={hasError("flushTime") ? formState.errors.flushTime[0] : null}
								value={formState.values.flushTime || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs>
							<TextField
								disabled
								size="small"
								fullWidth
								label="Tid mellem udslamning [timer]"
								name="flushTimeInterval"
								error={hasError("flushTimeInterval")}
								helperText={hasError("flushTimeInterval") ? formState.errors.flushTimeInterval[0] : null}
								value={formState.values.flushTimeInterval || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
				</form>
			</CardContent>
			<Typography className={classes.sectionHeaderAf} variant="h4" component="h4">
				Temperaturer
			</Typography>
			<CardContent className={classes.cardContent}>
				<form>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								disabled
								size="small"
								fullWidth
								label="Min. afkøling af fjernvarme [grader]"
								name="minDistentHeatCooling"
								error={hasError("minDistentHeatCooling")}
								helperText={hasError("minDistentHeatCooling") ? formState.errors.minDistentHeatCooling[0] : null}
								value={formState.values.minDistentHeatCooling || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs>
							<TextField
								disabled
								size="small"
								fullWidth
								label="Min. afganstemperatur [grader]"
								name="minHotWaterTemperature"
								error={hasError("minHotWaterTemperature")}
								helperText={hasError("minHotWaterTemperature") ? formState.errors.minHotWaterTemperature[0] : null}
								value={formState.values.minHotWaterTemperature || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs>
							<TextField
								disabled
								size="small"
								fullWidth
								label="Min. cirkulationstemperatur [grader]"
								name="minCirculationWaterTemperature"
								error={hasError("minCirculationWaterTemperature")}
								helperText={hasError("minCirculationWaterTemperature") ? formState.errors.minCirculationWaterTemperature[0] : null}
								value={formState.values.minCirculationWaterTemperature || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
				</form>
			</CardContent>
		</Card>
	);
}

Form.propTypes = {
	className: PropTypes.string,
	hasError: PropTypes.func,
	formState: PropTypes.object,
	handleChange: PropTypes.func,
	handleCheckboxChange: PropTypes.func,
};

export default Form;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Grid, TableContainer, Table, TableBody, TableCell, Paper, TableRow, Typography, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import Page from "../../../components/Page";
import Header from "./Header.js";
import axios from "../../../axios";
import Snackbar from "../../../components/Snackbar";
// import validate from "validate.js";
import { Doughnut } from "react-chartjs-2";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from '@material-ui/core/ListSubheader';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	inputContent: {
		marginTop: theme.spacing(1.5),
	},
	button: {
		marginTop: theme.spacing(3),
	},
	doughnot: {
		maxWidth: "200px",
	},
	spacer: {
		margin: "30px 0",
	},
	list: {
		borderRight: "1px solid rgb(192,192,192)",
		borderBottom: "1px solid rgb(192,192,192)",
		borderRadius: "5px"
	}
}));

const Installation = ({ match }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const { id } = match.params;
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("");
	const [configuration, setConfiguration] = useState(null);
	const [installation, setInstallation] = useState(null);
	const [alarms, setAlarms] = useState([]);
	const [notifications, setNotifications] = useState([]);
	const [lastFilterChanged, setLastFilterChanged] = useState(null);
	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [
			{
				label: "%",
				data: [],
				backgroundColor: [],
				borderWidth: 1,
			},
		],
	});

	useEffect(() => {
		// if the id is set we can fetch the installation from the api
		if (id) {
			setLoading(true);
			axios
				.get(`/api/installation/${id}`)
				.then((response) => {
					console.log(response);

					setInstallation(response.data.installation);
					setLastFilterChanged(response.data.lastFilterChanged);
					setConfiguration(response.data.installation.configuration);

					if (response.data.installation.configuration.func === 1) {
						const updatedChartData = {
							...chartData,
						};
						console.log(updatedChartData);

						const brugt = parseInt(response.data.installation.UsedCapacity);
						const tilbage = 100 - parseInt(response.data.installation.UsedCapacity);

						console.log(brugt, tilbage);

						const newLabels = ["Kapacitet Brugt", "Kapacitet Tilbage"];
						const Values = [brugt, tilbage];
						const backGroundColor = ["#36A2EB", "#c0c2c4"];
						const borderWidth = 1;

						updatedChartData.labels = newLabels;
						updatedChartData.datasets[0].data = Values;
						updatedChartData.datasets[0].backgroundColor = backGroundColor;
						updatedChartData.datasets[0].borderWidth = borderWidth;

						setChartData(updatedChartData);
					}
				})
				.catch((error) => {
					console.log(error);
				});
			setLoading(false);

			axios.get(`/api/installation/${id}/alarms`)
				.then((res) => {
					console.log(res);
					setAlarms(res.data.alarms);

				})
				.catch(error => {
					console.log(error);
				});
			axios.get(`/api/installation/${id}/notifications`)
				.then((res) => {
					console.log(res);
					setNotifications(res.data.notifications);

				})
				.catch(error => {
					console.log(error);
				})
		}
	}, [id, loading]);

	const handleSnackbarClick = (message, severity) => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		setSnackbarOpen(true);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") return;
		setSnackbarMessage("");
		setSnackbarSeverity("");
		setSnackbarOpen(false);
	};

	function statusMessage(st) {
		if (st <= 6) {
			return "Ingen alarm";
		} else if (st >= 16 && st <= 20) {
			return "Ionbytter er skiftet";
		} else if (st >= 32 && st <= 36) {
			return "Vand på gulvet";
		} else if (st === 49) {
			return "Vand på gulvet + Ionbytter er skiftet";
		} else if (st === 68) {
			return "Alarm eksternt udstyr (vandmålerklemmer)";
		} else if (st === 96) {
			return "Alarm eksternt udstyr (vandmålerklemmer) + Vand på gulvet";
		} else if (st === 113) {
			return "Alarm eksternt udstyr (vandmålerklemmer) + Vand på gulvet + Ionbytter tilsluttet";
		} else if (st >= 128 && st <= 131) {
			return "24h rapport";
		} else if (st === 145) {
			return "24h rapport + Ionbytter er skiftet";
		} else if (st === 160 || st === 161) {
			return "24h rapport + Vand på gulvet";
		} else if (st === 177) {
			return "24h rapport + Vand på gulvet + Ionbytter er skiftet";
		} else if (st === 225) {
			return "24h rapport + Alarm eksternt udstyr (vandmålerklemmer) + Vand på gulvet";
		} else {
			return "Ukendt";
		}
	}

	return (
		<Page className={classes.root}>
			<Container maxWidth="lg">
				<Header id={id} />
				{loading ? <CircularProgress /> : null}
				{configuration ? (
					<Grid container>
						<Typography component="h2" gutterBottom variant="h5">
							{configuration.Function.Description}
						</Typography>
					</Grid>
				) : null}
				{configuration && configuration.func === 1 ? (
					<Grid container className={classes.spacer}>
						<Grid item xs>
							<Doughnut data={chartData} />
						</Grid>
						<Grid item xs>
							<TableContainer component={Paper}>
								<Table className={classes.table} aria-label="simple table">
									<TableBody>
										<TableRow>
											<TableCell component="th" scope="row">
												Forventet skiftedato
											</TableCell>
											<TableCell align="right">{installation.ExpFilterExchange}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												Brugt Kapacitet
											</TableCell>
											<TableCell align="right">{installation.UsedCapacity + "%"}</TableCell>
										</TableRow>
											{lastFilterChanged.length > 0 ? (
											<TableRow>
												<TableCell component="th" scope="row">
													Sidste skifte dato
												</TableCell>
												<TableCell align="right">{lastFilterChanged[0].date}</TableCell>
											</TableRow>
											) : null}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
				) : null}

				{installation ? (
					<Grid container className={classes.spacer}>
						{configuration && configuration.func === 1 ? (
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="simple table">
									<TableBody>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>SIM Id</b>
											</TableCell>
											<TableCell align="right">{installation.id}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Kundenummer</b>
											</TableCell>
											<TableCell align="right">{installation.CustomerNr}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Installationsnummer</b>
											</TableCell>
											<TableCell align="right">{installation.InstallNr}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Kundenavn</b>
											</TableCell>
											<TableCell align="right">{installation.CustomerName}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Installations type</b>
											</TableCell>
											<TableCell align="right">{installation.InstallationType}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Filter type</b>
											</TableCell>
											<TableCell align="right">{installation.FilterType}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Filter størrelse</b>
											</TableCell>
											<TableCell align="right">{installation.FilterSize}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Bypass</b>
											</TableCell>
											<TableCell align="right">{installation.Bypass}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Capacity</b>
											</TableCell>
											<TableCell align="right">{installation.Capacity}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Hårdhed</b>
											</TableCell>
											<TableCell align="right">{installation.Hardness}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Postnummer</b>
											</TableCell>
											<TableCell align="right">{installation.PostNr}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Vandsensor type</b>
											</TableCell>
											<TableCell align="right">{installation.WaterSensorType}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						) : null}
						{configuration && configuration.func !== 1 ? (
							<TableContainer component={Paper} className={classes.spacer}>
								<Table className={classes.table} size="small" aria-label="simple table">
									<TableBody>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>SIM Id</b>
											</TableCell>
											<TableCell align="right">{installation.id}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Kundenummer</b>
											</TableCell>
											<TableCell align="right">{installation.CustomerNr}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Installationsnummer</b>
											</TableCell>
											<TableCell align="right">{installation.InstallNr}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Kundenavn</b>
											</TableCell>
											<TableCell align="right">{installation.CustomerName}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component="th" scope="row">
												<b>Installations type</b>
											</TableCell>
											<TableCell align="right">{installation.InstallationType}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						) : null}

						<Grid container className={classes.spacer}>
							{alarms.length > 0 ? (
								<Grid item xs>
									<List 
										className={classes.list}
										subheader={
											<ListSubheader component="div" id="nested-list-subheader">
												Seneste Alarmer
											</ListSubheader>
										} 
										component="div" 
										aria-label="main mailbox folders"
										>
										{alarms.map(alarm => (
											<ListItem button>
												<ListItemText primary={statusMessage(alarm.st)} secondary={alarm.stm} />
											</ListItem>
										))}
									</List>
								</Grid>
							) : null}
							{notifications.length > 0 ? (
								<Grid item xs>
									<List
										className={classes.list}
										subheader={
											<ListSubheader component="div" id="nested-list-subheader">
												Seneste data
											</ListSubheader>
										}
										component="div" 
										aria-label="main mailbox folders"
										>
										{notifications.map(notification => (
											<ListItem button>
												<ListItemText primary={statusMessage(notification.st)} secondary={notification.stm} />
											</ListItem>
										))}
									</List>
								</Grid>
							) : null}
						</Grid>
					</Grid>
				) : null}
			</Container>
			{snackbarMessage && snackbarOpen && snackbarSeverity ? (
				<Snackbar open={snackbarOpen} autoHideDuration={6000} message={snackbarMessage} onClose={handleSnackbarClose} severity={snackbarSeverity} />
			) : null}
		</Page>
	);
};

Installation.propTypes = {
	match: PropTypes.object.isRequired,
};

export default Installation;

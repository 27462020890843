import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Page from "../../../../components/Page";
import FormInput from "./FormInput";
import CreateButton from "./Button";
import axios from "../../../../axios";
import Snackbar from "../../../../components/Snackbar";
import validate from "validate.js";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	inputContent: {
		marginTop: theme.spacing(1.5),
	},
	button: {
		marginTop: theme.spacing(3),
	},
}));

let schema = {
	technician: {
		presence: { allowEmpty: false, message: "is required" },
	},
};

const initialState = {
	isValid: false,
	values: {},
	touched: {},
	errors: {},
};

const User = ({ match }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const { id } = match.params;
	const [isUpdate, setIsUpdate] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("");
	const [formState, setFormState] = useState(initialState);
	const [technicians, setTechnicians] = useState([]);

	useEffect(() => {
		// if id isset then we want to fetch the user from the api and fill the text fields
		if (id) {
			setIsUpdate(true);

			setFormState((prevFormState) => ({
				...prevFormState,
				values: {
					...prevFormState.values,
					id,
				},
				touched: {
					...prevFormState.touched,
					id: true,
				},
			}));
			axios
				.get("/api/user/technicians")
				.then((response) => {
					console.log(response.data);
					setTechnicians(response.data.technicians);
				})
				.catch((error) => console.log(error));
		}
	}, [id]);

	const handleChange = (event) => {
		event.persist();

		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	// Check if input has error
	const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState((prevFormState) => ({
			...prevFormState,
			isValid: !errors,
			errors: errors || {},
		}));
	}, [formState.values, setFormState]);

	const handleSnackbarClick = (message, severity) => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		setSnackbarOpen(true);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") return;
		setSnackbarMessage("");
		setSnackbarSeverity("");
		setSnackbarOpen(false);
	};

	const onUpdate = async () => {
		setLoading(true);

		const body = {
			userId: formState.values.technician,
			icc: formState.values.id,
		};
		console.log(body);

		try {
			if (isUpdate) {
				const updateTechnician = await axios.put("/api/sim/move", body);

				console.log(updateTechnician);

				if (updateTechnician.status === 200) {
					handleSnackbarClick("Sim kort er blevet flyttet", "success");
				}
			}
		} catch (error) {
			console.log(error, error.response);
			handleSnackbarClick("Kunne ikke flytte sim til anden tekniker. Prøv igen eller kontakt support", "error");
		}
		setLoading(false);
	};

	return (
		<Page className={classes.root}>
			<Container maxWidth="lg">
				<div>
					<Typography component="h2" gutterBottom variant="overline">
						Sim
					</Typography>
					<Typography component="h1" variant="h3">
						Flyt sim kort til en anden tekniker
					</Typography>
				</div>
				<FormInput
					technicians={technicians}
					id={id}
					isUpdate={isUpdate}
					hasError={hasError}
					formState={formState}
					handleChange={handleChange}
					className={classes.inputContent}
				/>
				<CreateButton isUpdate={isUpdate} formDisabled={!formState.isValid} loading={loading} onClick={onUpdate} className={classes.button} />
			</Container>
			{snackbarMessage && snackbarOpen && snackbarSeverity ? (
				<Snackbar open={snackbarOpen} autoHideDuration={6000} message={snackbarMessage} onClose={handleSnackbarClose} severity={snackbarSeverity} />
			) : null}
		</Page>
	);
};

User.propTypes = {
	match: PropTypes.object.isRequired,
};

export default User;

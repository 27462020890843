import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid, Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
	root: {},
}));

const Header = ({ id, className, ...rest }) => {
	const classes = useStyles();

	return (
		<div {...rest} className={clsx(classes.root, className)}>
			<Grid alignItems="flex-end" container justify="space-between" spacing={3}>
				<Grid item>					
					<Typography component="h1" variant="h3">
						Status information
					</Typography>
					<Typography component="h2" gutterBottom variant="overline">
						Status information
					</Typography>
				</Grid>
				<Grid item>
					<Button color="primary" variant="contained" component={RouterLink} to={"/installationer/opdater/" + id}>
						Opdater Installation
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};

Header.propTypes = {
	className: PropTypes.string,
	id: PropTypes.number
};

export default Header;

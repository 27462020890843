import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";

export default function configureStore(preloadedState = {}) {
	const middlewares = [thunkMiddleware];
	const middlewareEnhancer = composeWithDevTools(
		applyMiddleware(...middlewares)
	);

	const enhancers = [middlewareEnhancer];
	const composedEnhancers = compose(...enhancers);

	const store = createStore(rootReducer, preloadedState, composedEnhancers);

	return store;
}

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardHeader, CardContent, TextField, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {},
	formGroup: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	cardContent: {
		paddingTop: theme.spacing(1),
	},
}));

function Form({ hasError, formState, handleChange, handleCheckboxChange, className, ...rest }) {
	const classes = useStyles();

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<CardHeader title="" />
			<CardContent className={classes.cardContent}>
				<form>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								type="password"
								fullWidth
								label="Kodeord"
								name="password"
								error={hasError("password")}
								helperText={hasError("password") ? formState.errors.password[0] : null}
								value={formState.values.password || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs>
							<TextField
								type="password"
								fullWidth
								label="Gentag kodeord"
								name="confirmpassword"
								error={hasError("confirmpassword")}
								helperText={hasError("confirmpassword") ? formState.errors.confirmpassword[0] : null}
								value={formState.values.confirmpassword || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
				</form>
			</CardContent>
		</Card>
	);
}

Form.propTypes = {
	className: PropTypes.string,
	hasError: PropTypes.func,
	formState: PropTypes.object,
	handleChange: PropTypes.func,
	handleCheckboxChange: PropTypes.func,
};

export default Form;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardHeader, CardContent, TextField, Grid, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {},
	formGroup: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	cardContent: {
		paddingTop: theme.spacing(1),
	},
}));

function Form({ isUpdate, hasError, formState, handleChange, className, technicians, ...rest }) {
	const classes = useStyles();

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<CardHeader title="" />
			<CardContent className={classes.cardContent}>
				<form>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								disabled="disabled"
								autoFocus
								fullWidth
								label="Id"
								name="id"
								error={hasError("id")}
								helperText={hasError("id") ? formState.errors.id[0] : null}
								value={formState.values.id || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid>
						<div className={classes.formGroup}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="technician">Vælg tekniker</InputLabel>
								<Select labelId="technician" value={formState.values.technician || ""} onChange={handleChange} name="technician" label="Customer Type">
									<MenuItem value="">
										<em>None</em>
									</MenuItem>
									{technicians.map((technician, index) => (
										<MenuItem key={index} value={technician.id}>
											<em>{technician.initials}</em>
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
					</Grid>
				</form>
			</CardContent>
		</Card>
	);
}

Form.propTypes = {
	className: PropTypes.string,
	hasError: PropTypes.func,
	formState: PropTypes.object,
	handleChange: PropTypes.func,
	isUpdate: PropTypes.bool,
	technicians: PropTypes.array,
};

export default Form;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, TextField, Grid, Typography, FormControl, InputLabel, Select, CircularProgress, Button } from "@material-ui/core";
import axios from "../../../axios";
import a from "axios";

const useStyles = makeStyles((theme) => ({
	root: {},
	formGroup: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	cardContent: {
		paddingTop: theme.spacing(1),
	},
	formTitel: {
		marginTop: "10px",
	},
	green: {
		"& input:valid + fieldset": {
			borderColor: "green",
			borderWidth: 2,
		},
	},
	red: {
		"& input:invalid + fieldset": {
			borderColor: "red",
			borderWidth: 2,
		},
	},
}));

function Form({ isAlarm, hasError, formState, handleChange, className, onClick, loading, formDisabled, setFormState, isUpdate, ...rest }) {
	const classes = useStyles();
	const userId = useSelector((state) => state.auth.userId);
	const [filtertypes, setFilterTypes] = useState([]);
	const [functions, setFunctions] = useState([]);
	const [watermeters, setWaterMeters] = useState([]);
	const [filterbypass, setFilterBypass] = useState([]);
	const [filterSizes, setFilterSizes] = useState([]);
	const [hardnessIsDisabled, setHardnessIsDisabled] = useState(true);
	const [hardnessType, setHardnessType] = useState();
	const [hardnessLabel, setHardnessLabel] = useState("Hårdhed*");
	const [hardness, setHardness] = useState("");

	useEffect(() => {
		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				userId: userId,
			},
			touched: {
				...prevFormState.touched,
				userId: true,
			},
		}));
	}, [userId, setFormState]);

	useEffect(() => {
		a.all([axios.get("/api/function"), axios.get("/api/watermeter"), axios.get("/api/filter"), axios.get("/api/bypass")])
			.then(
				a.spread((...responses) => {
					const functions = responses[0];
					const watermeters = responses[1];
					const filterTypes = responses[2];
					const filterBypass = responses[3];

					setFilterTypes(filterTypes.data);
					setFunctions(functions.data);
					setWaterMeters(watermeters.data);
					setFilterBypass(filterBypass.data);
				})
			)
			.catch((error) => console.log(error));
	}, []);

	// When the postcode changes
	useEffect(() => {
		if (formState.values.PostNr) {
			if (formState.values.PostNr.length === 4) {
				axios
					.get("/api/hardness/" + formState.values.PostNr)
					.then((result) => {
						console.log(result.data[0]);
						setHardnessType(result.data[0]);
					})
					.catch((error) => {
						setHardnessType(null);
						console.log(error.response);
					});
			}
		}
	}, [formState.values.PostNr]);

	// When the Filter type changes
	useEffect(() => {
		if (formState.values.FilterType) {
			console.log(formState.values.FilterType);

			axios
				.get("/api/filtersize/" + formState.values.FilterType)
				.then((response) => {
					console.log(response);
					setFilterSizes(response.data);
				})
				.catch((err) => console.log(err));
		}
	}, [formState.values.FilterType]);

	useEffect(() => {
		filtertypes.map((filtertype) => {
			if (filtertype.Filter === formState.values.FilterType) {
				if (filtertype.HardnessType) {
					setHardnessLabel("Hårdhed " + filtertype.HardnessType + "*");
					setHardness(filtertype.HardnessType);
				} else {
					setHardnessLabel("Hårdhed*");
					setHardness("");
				}
			}
			return null;
		});
	}, [formState.values.FilterType, filtertypes]);

	useEffect(() => {
		if (hardnessType && formState.values.PostNr && hardness) {
			if (formState.values.PostNr.length === 4) {
				setHardnessIsDisabled(false);
				setFormState((prevFormState) => ({
					...prevFormState,
					values: {
						...prevFormState.values,
						Hardness: hardnessType[hardness],
					},
					touched: {
						...prevFormState.touched,
						Hardness: true,
					},
				}));
			}
		} else {
			setHardnessIsDisabled(true);
		}
	}, [formState.values.PostNr, hardnessType, hardness, setFormState]);

	useEffect(() => {
		if (formState.values.WaterSensorType !== "") {
			watermeters.map((watermeter) => {
				if (watermeter.WaterSensorType === formState.values.WaterSensorType) {
					setFormState((prevFormState) => ({
						...prevFormState,
						values: {
							...prevFormState.values,
							WaterSensorPL: watermeter.PulsPrLiter,
						},
						touched: {
							...prevFormState.touched,
							WaterSensorPL: true,
						},
					}));
				}
				return null;
			});
		}
	}, [formState.values.WaterSensorType, setFormState, watermeters]);

	// When the filter size changes
	useEffect(() => {
		let filterSizeId = formState.values.FilterSize;
		let bypass = formState.values.Bypass;
		let hardness = formState.values.Hardness;
		let capacity = 0;
		// We want to define the capacity for the filter and filter size
		if (filterSizeId && bypass && hardness) {
			axios
				.get(`/api/filtersize/byid/${filterSizeId}`)
				.then((res) => {
					capacity = res.data.capacity;

					let totalKapacitet = Math.ceil((capacity * bypass) / hardness);
					console.log(totalKapacitet);

					setFormState((prevFormState) => ({
						...prevFormState,
						values: {
							...prevFormState.values,
							PulseAtFullCapacity: totalKapacitet,
							Capacity: capacity,
						},
						touched: {
							...prevFormState.touched,
							PulseAtFullCapacity: true,
							Capacity: true,
						},
					}));
				})
				.catch((err) => console.log(err));
		}
	}, [formState.values.FilterSize, formState.values.Bypass, formState.values.Hardness, setFormState]);

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<CardContent className={classes.cardContent}>
				<form>
					<Typography className={classes.formTitel} component="h2" gutterBottom variant="overline">
						Kunde
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} lg={6} md={6}>
							<TextField
								autoFocus
								fullWidth
								label="Kundenavn*"
								name="CustomerName"
								error={hasError("CustomerName")}
								helperText={hasError("CustomerName") ? formState.errors.CustomerName[0] : null}
								value={formState.values.CustomerName || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} sm={6} lg={6} md={6}>
							<TextField
								fullWidth
								label="Salgsnummer"
								name="SalesNr"
								error={hasError("SalesNr")}
								helperText={hasError("SalesNr") ? formState.errors.SalesNr[0] : null}
								value={formState.values.SalesNr || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} lg={6} md={6}>
							<TextField
								fullWidth
								label="Kundenummer*"
								name="CustomerNr"
								error={hasError("CustomerNr")}
								helperText={hasError("CustomerNr") ? formState.errors.CustomerNr[0] : null}
								value={formState.values.CustomerNr || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} sm={6} lg={6} md={6}>
							<TextField
								fullWidth
								label="Installations nummer*"
								name="InstallNr"
								error={hasError("InstallNr")}
								helperText={hasError("InstallNr") ? formState.errors.InstallNr[0] : null}
								value={formState.values.InstallNr || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} lg={6} md={6}>
							<TextField
								className={hardnessType ? classes.green : classes.red}
								fullWidth
								label="PostNr*"
								name="PostNr"
								error={hasError("PostNr")}
								helperText={hasError("PostNr") ? formState.errors.PostNr[0] : null}
								value={formState.values.PostNr || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} sm={6} lg={6} md={6}>
							<TextField
								fullWidth
								label="Installations type*"
								name="InstallationType"
								error={hasError("InstallationType")}
								helperText={hasError("InstallationType") ? formState.errors.InstallationType[0] : null}
								value={formState.values.InstallationType || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} lg={6} md={6}>
							<TextField
								disabled={isUpdate ? true : false}
								fullWidth
								label="Sim kort nummer*"
								name="id"
								error={hasError("id")}
								helperText={hasError("id") ? formState.errors.id[0] : null}
								value={formState.values.id || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} sm={6} lg={6} md={6}>
							<FormControl disabled={isUpdate ? true : false} variant="outlined" fullWidth>
								<InputLabel id="Function">Funktion*</InputLabel>
								<Select
									native
									labelId="Function"
									value={formState.values.Function || ""}
									onChange={handleChange}
									name="Function"
									label="Customer Type"
								>
									<option value=""></option>
									{functions.map((func) => {
										return (
											<option key={func.FunctionCode} value={func.FunctionCode}>
												{func.Description}
											</option>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					{!isAlarm ? (
						<div>
							<Typography className={classes.formTitel} component="h2" gutterBottom variant="overline">
								Installation
							</Typography>
							<Grid className={classes.formGroup}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel id="WaterSensorType">Vandsensor type*</InputLabel>
									<Select
										native
										labelId="WaterSensorType"
										value={formState.values.WaterSensorType || ""}
										onChange={handleChange}
										name="WaterSensorType"
										label="Customer Type"
									>
										<option value=""></option>
										{watermeters.map((watermeter) => {
											return (
												<option key={watermeter.WaterSensorType} value={watermeter.WaterSensorType}>
													{watermeter.WaterSensorType}
												</option>
											);
										})}
									</Select>
								</FormControl>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={4} lg={4} md={4}>
									<FormControl variant="outlined" fullWidth>
										<InputLabel id="FilterType">Filter type*</InputLabel>
										<Select
											native
											labelId="FilterType"
											value={formState.values.FilterType || ""}
											onChange={handleChange}
											name="FilterType"
											label="Customer Type"
										>
											<option value=""></option>
											{filtertypes.map((filter) => {
												return (
													<option key={filter.Filter} value={filter.Filter}>
														{filter.Filter}
													</option>
												);
											})}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={4} lg={4} md={4}>
									<FormControl disabled={formState.values.FilterType ? false : true} variant="outlined" fullWidth>
										<InputLabel id="filtersize">Filter størrelse*</InputLabel>
										<Select
											native
											labelId="FilterSize"
											value={formState.values.FilterSize || ""}
											onChange={handleChange}
											name="FilterSize"
											label="Customer Type"
										>
											<option value=""></option>
											{filterSizes.map((filterSize) => {
												return (
													<option key={filterSize.id} value={filterSize.id}>
														{filterSize.size}
													</option>
												);
											})}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={4} lg={4} md={4}>
									<FormControl disabled={formState.values.FilterSize ? false : true} variant="outlined" fullWidth>
										<InputLabel id="Bypass">Filter Bypass*</InputLabel>
										<Select
											native
											labelId="Bypass"
											value={formState.values.Bypass || ""}
											onChange={handleChange}
											name="Bypass"
											label="Customer Type"
										>
											<option value=""></option>
											{filterbypass.map((bypass) => {
												return (
													<option key={bypass.Bypass} value={bypass.MultiplyFactor}>
														{bypass.Bypass}
													</option>
												);
											})}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={4} lg={4} md={4}>
									<TextField
										disabled={hardnessIsDisabled}
										fullWidth
										label={hardnessLabel}
										name="Hardness"
										error={hasError("Hardness")}
										helperText={hasError("Hardness") ? formState.errors.Hardness[0] : null}
										value={formState.values.Hardness || ""}
										onChange={handleChange}
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={12} sm={4} lg={4} md={4}>
									<TextField
										disabled={formState.values.WaterSensorType ? false : true}
										fullWidth
										label="Pulser/Liter*"
										name="WaterSensorPL"
										error={hasError("WaterSensorPL")}
										helperText={hasError("WaterSensorPL") ? formState.errors.WaterSensorPL[0] : null}
										value={formState.values.WaterSensorPL || ""}
										onChange={handleChange}
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={12} sm={4} lg={4} md={4}>
									<TextField
										fullWidth
										label="Alarm procent*"
										name="alarmPercent"
										error={hasError("alarmPercent")}
										helperText={hasError("alarmPercent") ? formState.errors.alarmPercent[0] : null}
										value={formState.values.alarmPercent || ""}
										onChange={handleChange}
										variant="outlined"
									/>
								</Grid>
							</Grid>
							<Typography className={classes.formTitel} component="h2" gutterBottom variant="overline">
								Beregnet kapacitet
							</Typography>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6} lg={6} md={6}>
									<div className={classes.formGroup}>
										<TextField
											disabled
											fullWidth
											label="Fuld kapacitet* "
											name="PulseAtFullCapacity"
											error={hasError("PulseAtFullCapacity")}
											helperText={hasError("PulseAtFullCapacity") ? formState.errors.PulseAtFullCapacity[0] : null}
											value={formState.values.PulseAtFullCapacity || ""}
											onChange={handleChange}
											variant="outlined"
										/>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} lg={6} md={6}>
									<div className={classes.formGroup}>
										<TextField
											fullWidth
											label="Korrigeret kapacitet"
											name="KorrigeretKapacitet"
											error={hasError("KorrigeretKapacitet")}
											helperText={hasError("KorrigeretKapacitet") ? formState.errors.KorrigeretKapacitet[0] : null}
											value={formState.values.KorrigeretKapacitet || ""}
											onChange={handleChange}
											variant="outlined"
										/>
									</div>
								</Grid>
							</Grid>
						</div>
					) : null}

					<Grid container spacing={1}>
						<Grid item xs>
							<div className={classes.formGroup} align="center">
								{loading ? (
									<CircularProgress />
								) : (
									<Button disabled={formDisabled} onClick={onClick} variant="contained" color="primary">
										{isUpdate ? "Opdater": "Opret installation"}
									</Button>
								)}
							</div>
						</Grid>
					</Grid>
				</form>
			</CardContent>
		</Card>
	);
}

Form.propTypes = {
	className: PropTypes.string,
	hasError: PropTypes.func,
	formState: PropTypes.object,
	handleChange: PropTypes.func,
	onClick: PropTypes.func,
	loading: PropTypes.bool,
	formDisabled: PropTypes.bool,
	setFormState: PropTypes.any,
	isAlarm: PropTypes.bool,
	isUpdate: PropTypes.bool
};

export default Form;

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, TextField, Grid, Typography, CircularProgress, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {},
	formGroup: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	cardContent: {
		paddingTop: theme.spacing(1),
	},
	formTitel: {
		marginTop: "10px",
	},
	green: {
		"& input:valid + fieldset": {
			borderColor: "green",
			borderWidth: 2,
		},
	},
	red: {
		"& input:invalid + fieldset": {
			borderColor: "red",
			borderWidth: 2,
		},
	},
}));

function Form({ hasError, formState, handleChange, className, onClick, loading, formDisabled, setFormState, ...rest }) {
	const classes = useStyles();
	const userId = useSelector((state) => state.auth.userId);

	useEffect(() => {
		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				userId: userId,
			},
			touched: {
				...prevFormState.touched,
				userId: true,
			},
		}));
	}, [userId, setFormState]);

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<CardContent className={classes.cardContent}>
				<form onSubmit={onClick}>
					<Typography className={classes.formTitel} component="h2" gutterBottom variant="overline">
						Sim kort
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12} lg={12} md={12}>
							<TextField
								size="small"
								autoFocus
								fullWidth
								label="Indtast IMSI eller MSISDN nummer*"
								name="sim"
								error={hasError("sim")}
								helperText={hasError("sim") ? formState.errors.sim[0] : null}
								value={formState.values.sim || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>

					<Grid container spacing={1}>
						<Grid item xs>
							<div className={classes.formGroup} align="center">
								{loading ? (
									<CircularProgress />
								) : (
									<Button disabled={formDisabled} onClick={onClick} variant="contained" color="primary">
										Registrer sim kort
									</Button>
								)}
							</div>
						</Grid>
					</Grid>
				</form>
			</CardContent>
		</Card>
	);
}

Form.propTypes = {
	className: PropTypes.string,
	hasError: PropTypes.func,
	formState: PropTypes.object,
	handleChange: PropTypes.func,
	onClick: PropTypes.func,
	loading: PropTypes.bool,
	formDisabled: PropTypes.bool,
	setFormState: PropTypes.any,
};

export default Form;

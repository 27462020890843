import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import validate from "validate.js";
import axios from "../../../axios";
import Page from "../../../components/Page";
import Snackbar from "../../../components/Snackbar";
import FormInput from "./FormInput";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	inputContent: {
		marginTop: theme.spacing(1.5),
	},
	button: {
		marginTop: theme.spacing(1),
	},
}));

const formSchema = {
	sim: {
		presence: { allowEmpty: false, message: "is required" },
		numericality: {
			onlyInteger: true,
			message: "Kan kun indeholde numre",
		},
		length: {
			is: [15, 20],
			message: "skal være 15 eller 20 tegn",
		},
	},
};

const initialState = {
	isValid: false,
	values: {},
	touched: {
		active: true,
	},
	errors: {},
};

const RegisterSim = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("");
	const [formState, setFormState] = useState(initialState);
	const [schema] = useState(formSchema);
	const state = useSelector((state) => state);

	const handleChange = (event) => {
		event.persist();

		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	// Check if input has error
	const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

	useEffect(() => {
		const errors = validate(formState.values, schema);
		setFormState((prevFormState) => ({
			...prevFormState,
			isValid: !errors,
			errors: errors || {},
		}));
	}, [formState.values, setFormState, schema]);

	const handleSnackbarClick = (message, severity) => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		setSnackbarOpen(true);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") return;
		setSnackbarMessage("");
		setSnackbarSeverity("");
		setSnackbarOpen(false);
	};

	const onRegister = async (event) => {
		event.preventDefault();
		setLoading(true);

		try {
			var body = formState.values;
			body["userId"] = state.auth.userId;
			const response = await axios.post("/api/sim", formState.values);

			if (response.status === 201) {
				handleSnackbarClick("Sim kort er nu registreret og klar til brug", "success");
				setFormState(initialState);
			} else {
				handleSnackbarClick("Der skete en fejl med registreringen af dit sim kort", "error");
			}
		} catch (error) {
			console.log(error.response);
			if (error.response.data.hasOwnProperty("message")) {
				handleSnackbarClick(error.response.data.message, "Error");
			} else {
				handleSnackbarClick("Der skete en fejl. Prøv igen eller kontakt support", "error");
			}
		}

		setLoading(false);
	};

	return (
		<Page className={classes.root}>
			<Container maxWidth="lg">
				<div>
					<Typography component="h1" variant="h3">
						Registrer sim kort
					</Typography>
				</div>
				<FormInput
					hasError={hasError}
					formState={formState}
					handleChange={handleChange}
					className={classes.inputContent}
					formDisabled={!formState.isValid}
					loading={loading}
					onClick={onRegister}
					setFormState={setFormState}
				/>
			</Container>
			{snackbarMessage && snackbarOpen && snackbarSeverity ? (
				<Snackbar open={snackbarOpen} autoHideDuration={10000} message={snackbarMessage} onClose={handleSnackbarClose} severity={snackbarSeverity} />
			) : null}
		</Page>
	);
};

export default RegisterSim;

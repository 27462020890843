import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import Search from "./Search";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		flexWrap: "wrap",
		marginBottom: "5px",
	},
	search: {
		flexGrow: 1,
		maxWidth: 480,
		flexBasis: 480,
	},
	filterButton: {
		marginLeft: "auto",
	},
	filterIcon: {
		marginRight: theme.spacing(1),
	},
}));

function SearchBar({ searchString, setSearchString, onSearch, className, ...rest }) {
	const classes = useStyles();

	return (
		<Grid {...rest} className={clsx(classes.root, className)} container spacing={3}>
			<Grid item>
				<Search searchString={searchString} setSearchString={setSearchString} className={classes.search} onSearch={onSearch} />
			</Grid>
		</Grid>
	);
}

SearchBar.propTypes = {
	className: PropTypes.string,
	onSearch: PropTypes.func,
	searchString: PropTypes.string,
	setSearchString: PropTypes.func,
};

export default SearchBar;
